export const FIELD = {
    ZIP_CODE: "address.postal_code",
    AUP_TYPE: "address.aup_type",
    CITY: "address.city",
    STREET: "address.street",
    POST_OFFICE: 'address.post_office',
    BUILDING_NR: "address.building_number",
    LOCUM_NR: "address.locum_number",
    VOIVODESHIP: "address.voivodeship",
  };

export const aupTypeOpts = [
  { value: "-", label: "-" },
  { value: "ul.", label: "Ulica" },
  { value: "pl.", label: "Plac" },
  { value: "al.", label: "Aleja" },
  { value: "os.", label: "Osiedle" },
];
