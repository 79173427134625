import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "config";
import { requestHeaders } from "config/headers";
import {AxiosResponse} from 'axios'
import {
  ICardSearchForm,
  ICodeHistoryFormValues,
  IWasteForwardFormValues,
  IWasteForwardBody,
  ITransferBody,
} from "types/waste-types";
import {IWasteCardCreateBody} from 'types/waste-card-types'
import {IActionState} from 'types/async-types'
import axiosInstance from "config/axios";
import _ from "lodash";

// post & patch
// interface IWasteForwardBody {
//   waste_transfer_card : {
//     transfers: Array<{
//       transfer_date: string;
//       address_id: string;
//       codes: Array<{
//         normalized_code: string;
//         amount: number;
//         unit: string;
//       }>;
//     }>;
//   }
 
// }

export const fetchCodes: () => Promise<AxiosResponse<any>> = async () => {
  const options: AxiosRequestConfig = {
    url: `${API_URL}/waste_codes`,
    headers: requestHeaders(),
    method: "GET",
  };
  // let res = null;
  const res  = await axios(options);
  // const result :IActionState = res.data;
  
  return res;
};

export const createWasteCard = async (data: IWasteCardCreateBody) => {
  
  const options: AxiosRequestConfig = {
    url: `${API_URL}/waste_cards`,
    headers: {
      Authorization: requestHeaders().Authorization,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
    method: "POST",
  };
  let res = null;

  res = await axios(options);
  const result = {
    ...res,
    successMessage: "Dodano nowy odpad",
  };
  return result;
};

export const showWasteCard = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${API_URL}/waste_cards/${id}/form_data`,
    method: "GET",
    headers: {
      Authorization: requestHeaders().Authorization,
    },
  };
  const res = await axiosInstance(options);
  return res;
};

export const editWasteCard = async (cardNumber: string, params: any) => {
  const data = {
    waste_card: {
      ...params,
      codes: _.values(params.codes),
    },
  };
  const options: AxiosRequestConfig = {
    url: `${API_URL}/waste_cards/${cardNumber}`,
    method: "PATCH",
    data: data,
    headers: {
      Authorization: requestHeaders().Authorization,
    },
  };
  const res = await axiosInstance(options);
  const result = {
    ...res,
    successMessage: "Zapisano dane",
  };
  return result;
};

export const editTransferCard = async (
  cardNumber: string,
  params: IWasteForwardFormValues
) => {
  const data = {};
  // const data = {
  //   waste_transfer_card: {
  //     transfer_date: params.transfer_date,
  //     codes: _.values(params.codes),
  //   },
  // };
  const options: AxiosRequestConfig = {
    url: `${API_URL}/waste_transfer_cards/${cardNumber}`,
    method: "PATCH",
    data: data,
    headers: {
      Authorization: requestHeaders().Authorization,
    },
  };
  const res = await axiosInstance(options);
  return res;
};

export const deleteWasteCard = async (cardNumber: string) => {
  const options: AxiosRequestConfig = {
    url: `/waste_cards/${cardNumber}`,
    method: "DELETE",
    headers: {
      Authorization: requestHeaders().Authorization,
    },
  };
  const res = await axiosInstance(options);
  const result = {
    ...res,
    successMessage: "Usunięto element!",
  };
  return result;
};

export const fetchAddedCode = async (code: string) => {
  const options: AxiosRequestConfig = {
    url: `/wastes/${code}`,
    method: "GET",
    headers: {
      Authorization: requestHeaders().Authorization,
    },
  };
  let res = null;
  res = await axiosInstance(options);
  return res;
};

export const fetchCodeHistory = async (
  code: string,
  params: ICodeHistoryFormValues
) => {
  let query = "";

  if (params) {
    if (params.page) {
      query += `page=${params.page}&`;
    }
    if (params.year) {
      query += `year=${params.year}&`;
    }
  }

  const options: AxiosRequestConfig = {
    url: `/wastes/${code}/history?${query}`,
    method: "GET",
    headers: {
      Authorization: requestHeaders().Authorization,
    },
  };
  let res = null;
  res = await axiosInstance(options);
  return res;
};

export const fetchAddedCodes = async () => {
  const options: AxiosRequestConfig = {
    url: `${API_URL}/wastes`,
    headers: {
      Authorization: requestHeaders().Authorization,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "GET",
  };
  let res = null;

  res = await axios(options);
  return res;
};

export const fetchCardsHistory = async (params: ICardSearchForm | null) => {
  let query = "";

  if (params) {
    if (params.card_number) {
      query += `search[card_number]=${params.card_number}&`;
    }
    if (params.operation_type) {
      query += `search[operation_type]=${params.operation_type}&`;
    }
  }

  const options: AxiosRequestConfig = {
    url: `/wastes/cards_history?${query}`,
    method: "GET",
    headers: {
      Authorization: requestHeaders().Authorization,
    },
  };

  let res = null;
  res = await axiosInstance(options);
  
  return res;
};

export const fetchTransferCodes = async (query: string) => {
  const options: AxiosRequestConfig = {
    url: `/waste_transfer_cards/transfer_data${query}`,
    method: "GET",
    headers: {
      Authorization: requestHeaders().Authorization,
    },
  };

  const res = await axiosInstance(options);
  return res;
};

export const createTransferCard = async (params: IWasteForwardFormValues) => {
  
  let transfers : Array<ITransferBody> = Object.keys(params.transfers).map((key) => {
    const codesArray  =  _.values(params.transfers[key].codes);
    let transfer : ITransferBody = {
      transfer_date : params.transfer_date,
      address_id : key,
      codes : codesArray
    }
    return transfer;
})
  let data : IWasteForwardBody = {
    waste_transfer_card : {
      card_type : params.card_type,
      card_number : params.card_type == 'company_export' ? params.card_number : undefined,
      transfers
    }
  }

  const options: AxiosRequestConfig = {
    url: `/waste_transfer_cards`,
    method: "POST",
    data: data,
    headers: {
      Authorization: requestHeaders().Authorization,
    },
  };
  const res = await axiosInstance(options);
  const result = {
    ...res,
    successMessage: "Przekazano wybrane odpady",
  };
  return result;
};

export const deleteTransferCard = async (cardNumber: string) => {
  const options: AxiosRequestConfig = {
    url: `/waste_transfer_cards/${cardNumber}`,
    method: "DELETE",
    headers: {
      Authorization: requestHeaders().Authorization,
    },
  };
  const res = await axiosInstance(options);
  const result = {
    ...res,
    successMessage: "Usunięto element!",
  };
  return result;
};
