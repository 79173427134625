import React, { useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import DocsMenu from "components/layout/docs-menu";
import UserMenu from "components/layout/user-menu";
import {  SYSTEM_BASE_URL } from "config";
import { openMenuLink } from "actions/user";
import { IUser } from "types/user-types";
import { useAsyncAction } from "utils/async-action";
import { Link, useLocation } from "react-router-dom";
import { PATH } from "../../path";
import style from "./navbar.module.scss";

interface IResUrl {
  url: string;
}

function Navbar() {
  const location = useLocation();
  
  const [openMenuLinkState, execOpenMenuLink] = useAsyncAction(openMenuLink);
  const [userMenuVisible, setUserMenuVisible] = useState(false);
  const [docsMenuVisible, setDocsMenuVisible] = useState(false);
  const user: IUser = useSelector((state: any) => state.user);
  const menuItems = [
    {
      link: "/",
      title: "ADMINISTRACJA ODPADÓW",
      class: "item-level-1",
      local: true,
      key: "waste-register",
    },
    {
      link: `${SYSTEM_BASE_URL}/?Services/Add`,
      title: "DODAJ POJAZD",
      class: "item-level-1",
    },
    {
      link: `${SYSTEM_BASE_URL}/?Services/Browse`,
      title: "MOJE POJAZDY",
      class: "item-level-1",
    },
    {
      link: `${SYSTEM_BASE_URL}/services/add`,
      title: "BDO",
      class: "item-level-1",
    },
    {
      link: `${SYSTEM_BASE_URL}/?Documents/Browse`,
      title: "DOKUMENTY",
      class: "item-level-1",
    },
    { title: "ROZLICZENIA", class: "item-level-1", key: "docs" },
  ];

  const openLink = async (url: string) => {
    let urlObj = new URL(url);
    const res: any = await execOpenMenuLink(urlObj.search);
    const data: IResUrl = res.data;
    window.location.href = data.url;
  };

  const renderMenuItems = () => {
    const items = user.menu_items;
    if (!items) {
      return <></>;
    }
    if ((location.pathname === PATH.WASTE_REGISTER) && (location.pathname === '/404')) {
      return <div></div>;
    }
    return Object.keys(items).map((key, index) => {
      return (
        <div key={index}>
          {key === "wastes" ? (
            <Link
              className={`item-level-1 ${isSelected() && "current"}`}
              to="/"
            >
              <div>{items[key].label}</div>

              <span className={style.new}>Nowość</span>
            </Link>
          ) : (
            <a
              className={`item-level-1`}
              onClick={() => openLink(items[key].url)}
              // onClick={item.key == 'docs' ? (() => setDocsMenuVisible(true)) : null}
            >
              {items[key].label}{" "}
              {/* {(item.key == 'docs' && docsMenuVisible) &&
                <DocsMenu  setDocsMenuVisible={setDocsMenuVisible} />
              } */}
            </a>
          )}
          {/* )} */}
        </div>
      );
    });
  };

  const isSelected = () => {
    if (location.pathname === "/" || location.pathname === PATH.WASTE) {
      return true;
    }
    return false;
    // switch(link){
    //   case `${PAGE.VEHICLE}/[id]`:
    //     return [`${PAGE.MY_VEHICLES}`];
    //   default :
    //     return [router.pathname];
    // }
  };

  return (
    <div id="header-panel">
      <div id="h-header"></div>
      <div id="h-body">
        <span className="logo">
          <a href={SYSTEM_BASE_URL}>
            <img
              src="/images/logo.png"
              alt="Integrator - logo"
              title="Integrator"
            />
          </a>
        </span>
        <div id="menu-panel" className={style.menu_panel}>
          {renderMenuItems()}
          {(location.pathname != PATH.WASTE_REGISTER && location.pathname != '/404') && (
            <div>
              <a
                className="item-level-1"
                onClick={() => setUserMenuVisible(true)}
              >
                <img className="user_img" src="/images/userpanel.png" />
              </a>
              {userMenuVisible && (
                <UserMenu
                  user={user}
                  execOpenMenuLink={execOpenMenuLink}
                  setUserMenuVisible={setUserMenuVisible}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Navbar);
