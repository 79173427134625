import React, { ReactElement } from "react";
import { Field, Formik } from "formik";
import style from "./check-form-field.module.scss";


interface IProps {
  name : string,
  cbxLabel? : string | JSX.Element | JSX.Element[] ,
  orientation? : 'vertical',
  className? : string,
  value : string,
  onCheckChange? : (a: any) => void,
  id: string,
  disabled? : boolean,
  isFlag? : boolean
  validate? : any
  required?: boolean
  doubleRequired? : boolean

}

function Checkbox(props: IProps) {
  
  
  
  return (
    <Field validate={props.validate ? props.validate : undefined} name={props.name}>
      {({ field, form } : any) => {
        if(!field.value){
          
        }
        const { cbxLabel, onCheckChange, ...inputProps } = props;
        
        delete inputProps.validate;
       return <div
          className={`${props.cbxLabel ? style.form_checkbox : ""} checkbox ${props.className} ${
            props.orientation == "vertical" ? style.vertical : style.horizontal
          }`}
        >
          <input
            data-testid="checkbox"
            type="checkbox"
            {...inputProps}
            checked={field.value.includes(props.value)}
            value={inputProps.value}
            
            onChange={(e) => {
              
              if (field.value.includes(props.value)) {
                const nextValue = field.value.filter(
                  (value: string) => value !== props.value
                );
                form.setFieldValue(props.name, nextValue);
              } else {
                const nextValue = field.value.concat(props.value);
                form.setFieldValue(props.name, nextValue);
              }
              if (props.onCheckChange) {
                props.onCheckChange(e);
              }
            }}
          />
          <label className={`${props.required ? style.required : undefined} ${props.doubleRequired ? style.double : undefined}`}  data-testid="label" htmlFor={props.id}>{props.cbxLabel}</label>
        </div>
      }}
    </Field>
  );
}

export default Checkbox;

