import { toast } from "react-toastify";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "styles/common/toaster.scss";
import React from "react";

export const successMessage = (messageText) =>
  toast.success(
    <span className="toast-content">
      {" "}
      <CheckCircleIcon />
      {messageText}
    </span>,
    {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }
  );

export const errorMessage = (messageText, errorItems) => {
  let messageList  = null;
  
  if(errorItems){
     messageList = Object.keys(errorItems).map((key,index) => {
        return <li>{errorItems[key]}</li>
    })
  }
 


  return toast.error(<div><span className="toast-content">{messageText}</span><ul className="error-items">
      {messageList}
    </ul></div>, {
    position: "top-center",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
