import React from "react";
import FormButtons from "./form-buttons";
import { FIELD } from "form-config/waste-service-config";
import { PATH } from "../../../path";
import { renderCheckbox } from "components/input/table-fields";
import CheckFormField from "components/input/check-form-field";
import style from "./waste-register-info.module.scss";
import { useFormikContext } from "formik";
import parse from 'html-react-parser';

//mocks

const RegisterInfo = (props: any) => {
  const { formButtonsProps, formData } = props;
  const {setFieldValue} = useFormikContext();

  const fields = {
    WASTE_REGISTER: {
      name: FIELD.SERVICE_OPTION,
      type: "checkbox",
      label: "Typ klienta",
      required: true,
      onCheckChange : (e: any) => {
        console.log('change cbx')
        setFieldValue(FIELD.SERVICE_OPTION, ['val1'] );
      },
      
      options: [
        {
       
          checked : true,
          
          value: "val1",
          id: "val1",
          cbxLabel: "Administracja i sprawozdawczość odpadów",
        },
      ],
    },

    WASTE_COLLECTION: {
      name: "waste_collection",
      type: "checkbox",
      disabled: true,

      label: "",

      options: [
        {
          value: "true",
          id: "val2",
          cbxLabel: "Odbiór odpadów",
        },
      ],
    },
  };

  return (
    <div>
      <div className={style.info}>
        <div className={style.tcl}>
          <div>
            <p className="md-t test-class">
              Oferujemy narzędzia systemowe ułatwiające prowadzenie
              administracji oraz sprawozdawczości firmom, które są wytwórcami
              odpadów. Współpraca z Integratorem pomoże zestawić dane o
              odpadach, przygotować sprawozdania, stanowi wsparcie w
              wywiązywaniu się z obowiązków nałożonych przepisami prawa.
            </p>
            {/* <p className="md-t">
              Roczny koszt usługi administracja i sprawozdawczość to{" "}
              <span className="md-b-t green">300 zł netto </span>, z
              zastrzeżeniem postanowień warunków szczególnych. Usługa dostępna
              po opłaceniu faktury proforma, w sposób w fakturze zastrzeżony.
            </p> */}
            <p className="md-b-t">W ramach usług dla warsztatów oferujemy:</p>
            <div className={style.options}>
              {renderCheckbox(fields.WASTE_REGISTER)}
            </div>

            <div className="price">
              <p className="md-b-t">Cena:</p>
              {formData.data && formData.data.product_price && (
                <>
                  <span className="value">
                    {parseFloat(formData.data.product_price).toFixed(2)} zł
                    netto
                  </span>
                  <span className="md-t"> za rok sprawozdawczy</span>
                </>
              )}
            </div>
            <FormButtons {...formButtonsProps} />
            <div className={`${style.options} mt-20`}>
              {renderCheckbox(fields.WASTE_COLLECTION)}
            </div>
            <p className={`${style.option_text} md-t`}>
              {parse(
              "Usługa nie jest jeszcze dostępna, w momencie jej uruchomienia\
              zniknie komunikat dotyczący jej niedostępności i umieścimy wtedy\
              informacje jak uzyskać dostęp do tej usługi. Już dziś możesz\
              jednak poprzez wybór pola Odbiór odpadów wskazać nam, że jesteś\
              zainteresowany informacjami o usłudze – będziemy się z Tobą\
              kontaktować, zgodnie z podanymi przez Ciebie Danymi, aby udzielić\
              informacji o usłudze, a gdy usługa zostanie uruchomiona udzielimy\
              Tobie ewentualnej pomocy w dostępie do niej. Wybór pola Odbiór\
              odpadów jest możliwy tylko dla Klientów, którzy wybrali Pole\
              Administracja i sprawozdawczość odpadów celem uzyskania dostępu do\
              usługi Administracja i sprawozdawczość odpadów. Niniejsza\
              informacja (jak i inne informacje o usłudze) o usłudze nie stanowi\
              oferty w rozumieniu przepisów kodeksu cywilnego, w szczególności w\
              rozumieniu art. 66 i 66&sup1 kc")}
            </p>
            {/* <p className="md-t">
                Informacja o usługach obejmuje jedynie usługi aktywne, co dotyczy
                również zastrzeżenia o cenie. W przypadku wprowadzenia zmian treść
                informacji o usługach zostanie dostosowana. Zmianie ulegną także
                warunki szczególne usługi.
              </p> */}
          </div>

          <div>
            <h2>
              Jak uzyskać dostęp do usługi Administracja i sprawozdawczość
              odpadów?
            </h2>

            <ol className={style.flow_list}>
              <li>
                <p className="md-b-t heavy">
                  Zapoznaj się z{" "}
                  <a target="_blank" href="https://integrator.org.pl/files/warunki_szczegolne_uslugi.pdf">
                    Warunkami szczególnymi usługi
                  </a>
                  , z naszym
                  <a
                    target="_blank"
                    href="https://integrator.org.pl/files/regulamin_serwisu_v4.pdf"

                    
                  >
                    {" "}
                    Regulaminem Serwisu Internetowego{" "}
                  </a>
                  ,{" "}
                  <a
                    target="_blank"
                    href="https://integrator.org.pl/files/regulamin_systemu_v4.pdf"
                  >
                    Regulaminem Systemu Integrator{" "}
                  </a>{" "}
                  i{" "}
                  <a
                    target="_blank"
                    href="https://integrator.org.pl/files/polityka_prywatnosci_v2.pdf"
                  >
                    Polityką Prywatności{" "}
                  </a>
                  , które mają zastosowanie w przypadku świadczonych przez nas
                  usług.
                </p>
              </li>
              <li>
                <p className="md-b-t">Zamów usługę</p>
                <p className="md-t">
                  Jeśli decydujesz się na uruchomienie usługi (co jest możliwe
                  jedynie bez zastrzeżeń) wypełnij i wyślij formularz zamówienia
                  usługi.
                </p>
              </li>
              <li>
                <p className="md-b-t">Opłać fakturę pro-forma </p>
                <p className="md-t">
                  Roczny koszt usługi to{" "}
                  {formData.data && formData.data.product_price
                    ? parseFloat(formData.data?.product_price).toFixed(2)
                    : ""}{" "}
                  {parse(
                    "zł netto za rok sprawozdawczy, z zastrzeżeniem postanowień\
                    warunków szczególnych. Z chwilą otrzymania przez nas płatności\
                    umowa na usługę jest uważana za zawartą. Niniejsza informacja\
                    o usłudze nie stanowi oferty w rozumieniu przepisów kodeksu\
                    cywilnego, w szczególności w rozumieniu art. 66 i 66&sup1; kc. Po\
                    zaksięgowaniu wpłaty wyślemy Ci fakturę VAT."                  
                    )
                  }
                 
                </p>
              </li>
              <li>
                <p className="md-b-t">Dostęp do usługi </p>
                <p className="md-t">
                  Usługa dostępna jest po opłaceniu faktury proforma, w sposób w
                  fakturze zastrzeżony. Usługa zostanie uruchomiona na twoim
                  koncie w Systemie Integrator niezwłocznie od otrzymania przez
                  nas płatności. Jeżeli nie posiadasz konta w Systemie
                  Integrator po zaksięgowaniu wpłaty otrzymasz również login i
                  hasło do Systemu Integrator. W przypadku posiadania już konta
                  w Systemie Integrator po zaksięgowaniu wpłaty otrzymasz w nim
                  funkcjonalności dotyczące warsztatów.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterInfo;
