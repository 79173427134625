import parse from 'html-react-parser';

export interface IText {
    text : string;
    is_required? : boolean;
    extras : {[key : string]: {
        link : string;
        text : string;
    }}
}


export const replaceProps = (valFrom: any, valTo : any, data: any) => {
    
    Object.keys(data).forEach(function(key) {
    if(data[key] === valFrom) {
        data[key] = valTo;
    }
})
}

export const renderTextWithLinks = (textItem: IText) => {
    let text = textItem.text;
    Object.keys(textItem.extras).map((key, index) => {
       text = text.replace(`#${key}#`, `<a target="_blank" href=${textItem.extras[key].link}>${textItem.extras[key].text}</a>`);
    })
    return parse(text);
}