import axios from "axios";
import { API_URL } from "config";
import { requestHeaders } from "config/headers";

//**** regular API calls */
export const fetchFormData = async () => {
  const options = {
    url: `${API_URL}/form_data`,
    headers: { "content-type": "application/json" },
    method: "GET",
  };
  let res = await axios(options);
  localStorage.setItem("token", res.data.api_token);
  return res;
};

export const fetchCompanyByTax = async (taxNumber) => {
  const options = {
    url: `${API_URL}/check_gus?tax_number=${taxNumber}`,
    headers: requestHeaders(),
    method: "GET",
  };
  let res = null;
  res = await axios(options);
  return res;
};

export const checkREGON = async (regonNumber) => {
  const options = {
    url: `${API_URL}/check_regon?regon_number=${regonNumber}`,
    headers: requestHeaders(),
    method: "GET",
  };
  let res = null;
  res = await axios(options);
  return res;
};

export const checkPostalCode = async (postalCode, strip = null) => {
  const options = {
    url: `${API_URL}/check_postal_code?code=${postalCode}${
      strip ? `&strip=${strip}` : ""
    }`,
    headers: requestHeaders(),
    method: "GET",
  };
  let res = null;
  res = await axios(options);
  return res;
};

export const fetchAddresses = async () => {
  const options = {
    url: `${API_URL}/addresses`,
    headers: {
      Authorization: requestHeaders().Authorization,
      Accept: "application/json",
    },
    method: "GET",
  };
  let res = null;
  res = await axios(options);
  return res;
};

export const createAddress = async (data) => {
  const options = {
    url: `${API_URL}/addresses`,
    headers: {
      Authorization: requestHeaders().Authorization,
      Accept: "application/json",
    },
    method: "POST",
    data
  };
  let res = null;
  res = await axios(options);
  res.successMessage = "Dodano nowy adres";
  return res;
};
