import React from "react";
 
 const FormButtons = ({ isValid, validateForm, submitForm, setStep, stepNo, position, disabled, onClick }: any) => {
    const prevStep = stepNo - 1;
    const nextStep = stepNo + 1;
    
    return (
      <div className={`buttons ${position}`}>
        {stepNo > 1 && (
          <a
            className="button inverted-button"
            onClick={() => setStep(prevStep, validateForm)}
          >
            Wstecz
          </a>
        )}
        {stepNo == 2 ? (
          <button
            disabled={!isValid || disabled}
            type="button"
            className="button button-link"
            onClick={onClick || submitForm}
          >
            Wyślij zgłoszenie
          </button>
        ) : (
          <button
            type="button"
            data-testid="go-to-form"
            className="button button-link"
            disabled={!isValid}
            onClick={() => setStep(nextStep, validateForm)}
          >
            Zamów usługę
          </button>
        )}
      </div>
    );
  };

  export default FormButtons;