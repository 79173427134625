import React from 'react'
import styles from './panel.module.scss';
const Card = ({noMargin,children, className}: {noMargin?: boolean , children: any, className?: string }) => {

  return (
    <div className={`${styles.panel_default} ${noMargin && 'mb-0'} ${className}`}>
      {children}
    </div>
  )
}

export default Card;
