import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeader from "components/common/page-header";
import SpinnerPopup from "components/common/spinner-modal";
import { useParams } from "react-router-dom";
import Panel from "components/common/panel";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import {
  fetchAddedCode,
  fetchCodeHistory,
  deleteTransferCard,
  deleteWasteCard,
} from "actions/waste";
import Alert from "components/common/alert";
import { useAsyncAction } from "utils/async-action";
import { ConfirmAlert } from "components/common/confirm-alert";
import {
  IWasteCode,
  IStats,
  IHistory,
  ICode,
  ICardItem,
  ICodeHistoryFormValues,
} from "types/waste-types";
// import { makeStyles } from '@material-ui/core/styles';
import Pagination from "components/common/pagination";
import style from "./waste-details.module.scss";

import { PATH } from "../../path";

function WasteDetails(props: any) {
  const { code }: { code: string } = useParams();
  const [summaryYear, setSummaryYear] = useState<string>("2020");
  const [pageNo, setPageNo] = useState<number>(1);
  const [codeState, execFetchAddedCode] = useAsyncAction(fetchAddedCode);
  const [historyState, execFetchCodeHistory] = useAsyncAction(fetchCodeHistory);
  const [historyData, setHistoryData] = useState<IHistory | null>(null);

  const [transferCardState, execDeleteTransferCard] = useAsyncAction(
    deleteTransferCard
  );

  const [wasteCardState, execDeleteWasteCard] = useAsyncAction(deleteWasteCard);

  /** functions */
  /** functions  *********************************/
  const deleteItem = (operationType: string, cardNumber: string) => {
    switch (operationType) {
      case "import":
        ConfirmAlert(() => execDeleteWasteCard(cardNumber));
        break;
      case "import_export":
        ConfirmAlert(() => execDeleteWasteCard(cardNumber));
        break;
      case "export":
        ConfirmAlert(() => execDeleteTransferCard(cardNumber));
        break;
    }
  };

  const onPageChange = (e: any, page: number) => {
    const params: ICodeHistoryFormValues = {
      year: summaryYear,
      page: page,
    };
    setPageNo(page);
    execFetchCodeHistory(code, params);
  };

  const onYearChange = (year: string) => {
    const params: ICodeHistoryFormValues = {
      year: year,
      page: pageNo,
    };
    setSummaryYear(year);
    execFetchCodeHistory(code, params);
  };

  /** side effects */

  useEffect(() => {
    execFetchAddedCode(code);
  }, []);

  useEffect(() => {
    execFetchAddedCode(code);
  }, [transferCardState.data, wasteCardState.data]);

  useEffect(() => {
    if (codeState.data) {
      const code: IWasteCode = codeState.data;
      setHistoryData(code.history);
    }
  }, [codeState.data]);

  useEffect(() => {
    if (historyState.data) {
      const history: IHistory = historyState.data.history;
      setHistoryData(history);
    }
  }, [historyState.data]);

  function renderListItems(history: IHistory | null) {
    if (!history) {
      return <div></div>;
    }

    return history.items.map((item: ICardItem, index: number) => {
      // const remained = item.produced - (item.transfered === '-' ? 0 :
      return (
        <tr key={index} className="odd record-137720-row first">
          <td className={`${style.bold}text-left`}>{item.created_at}</td>{" "}
          <td className={`text-left`}>
            {/* <img src="/images/circle-off-outline.svg" /> */}
            <div className={style.status}>
              {/* <img src="/images/checkbox-marked.svg" /> */}
              <div>
                {/* <div className="green">Przekazane</div> */}
                <div>{item.card_number}</div>
              </div>
            </div>
          </td>{" "}
          <td className={` text-left`}>{item.manufacture_date}</td>{" "}
          <td className={` text-left`}>{item.transfer_date}</td>{" "}
          <td className={` text-left`}>{item.address}</td>{" "}
          <td className={`${style.bold} lg-t text-right`}>+ {item.produced}</td>{" "}
          <td className={`${style.bold} lg-t text-right green`}>
            {item.transfered != "-" ? `${item.transfered}` : "-"}
          </td>{" "}
          {/* <td className={`${style.bold} lg-t text-right red`}>{item.produced - item.transferred} kg</td>{" "} */}
          <td>
            <div className={`actions`}>
              {!item.read_only && (
                <>
                  <Link to={`${PATH.WASTE_CARD_EDIT}/${item.id}`}>
                    <EditIcon className={`edit`} />{" "}
                  </Link>
                  <ClearIcon
                    onClick={() =>
                      deleteItem(item.operation_type, item.import_id)
                    }
                  />
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }

  const tabProps = {
    selectedClassName: style.selected,
    className: style.tab,
  };
  const year2020 = {
    className: `${style.tab} ${summaryYear == "2020" && style.selected}`,
    onClick: () => onYearChange("2020"),
  };
  const year2019 = {
    className: `${style.tab} ${summaryYear == "2019" && style.selected}`,
    onClick: () => onYearChange("2019"),
  };
  const allYears = {
    className: `${style.tab} ${summaryYear == "all" && style.selected}`,
    onClick: () => onYearChange("all"),
  };

  if (
    codeState.loading ||
    !codeState.response ||
    historyState.loading ||
    !historyData
  ) {
    return <SpinnerPopup />;
  }

  const data: IWasteCode = codeState.data;
  const {
    stats,
    history,
    waste_code,
  }: { stats: IStats; history: IHistory; waste_code: ICode } = data;
  return (
    <div>
      {codeState.loading && <SpinnerPopup />}
      <PageHeader noMargin={true} title={`Odpad ${waste_code.code}`} />
      <div className={`lg-t title`}>{waste_code.name}</div>
      <div className={`${style.tablist}`}>
        <div {...year2020}>2020</div>
        <div {...year2019}>2019</div>
        <div {...allYears}>Wszystkie</div>
      </div>
      <h2>Podsumowanie</h2>
      <Panel>
        <div className={`${style.summary}`}>
          <div className={`${style.waste_kg}`}>
            <span>Łącznie wytworzone:</span>
            <div className={`${style.amount}`}>{stats.produced} </div>
            <div className={`${style.kg}`}>kg</div>
          </div>
          <div className={`${style.waste_kg}`}>
            <span>Nieprzekazanych:</span>
            <div className={`${style.amount} red`}>{stats.remained} </div>
            <div className={`${style.kg}`}>kg</div>
          </div>
          <div className={`${style.waste_kg}`}>
            <span>Przekazanych:</span>
            <div className={`${style.forwarded}`}>
              <div className={`${style.forwarded_kg}`}>
                <div className={`${style.amount} green`}>
                  {stats.transfered}{" "}
                </div>
                <div className={`${style.kg}`}>kg</div>
              </div>
              <div className={`${style.bar_container}`}>
                <div className={` green`}>{stats.percent} % </div>
                <div className={`${style.forwarded_bar}`}>
                  <div
                    className={`${style.fill}`}
                    style={{ width: `${stats.percent}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {!waste_code.is_dangerous && (
            <div className={`${style.forward_btn}`}>
              <Link
                to={`${PATH.WASTE_FORWARD}?waste_codes[]=${waste_code.normalized_code}`}
                className={`button`}
              >
                Przekaż odpad
              </Link>
            </div>
          )}
        </div>
      </Panel>
      <h2>Historia wpisów</h2>
      {!historyData.items.length ? (
        <Alert message="Brak elementów do wyświetlenia" />
      ) : (
        <table className="list waste-list">
          <thead id="thead-browse">
            <tr>
              <th className={`text-left`}>Data wpisu</th>
              <th className={`text-left`}>
                Nr karty / identyfikator przekazania
              </th>
              <th className={`text-left`}>Data wytworzenia</th>
              <th className={`text-left`}>Data przekazania</th>
              <th className={`text-left`}>Miejsce wytworzenia</th>
              <th className={`text-right`}>Wytworzono</th>
              <th className={`text-right`}>Przekazano</th>
              {/* <th className={`text-right`}>Saldo nieprzekazanych</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody id="tbody-browse">{renderListItems(historyData)}</tbody>
        </table>
      )}

      <Pagination count={historyData.total_pages} onPageChange={onPageChange} />
    </div>
  );
}

export default WasteDetails;
