import React, { useState, useEffect, useRef } from "react";
import { Formik, FormikProps, useFormikContext } from "formik";
import { Link, useHistory } from "react-router-dom";
import { PATH } from "../../../../path";
import Alert from "components/common/alert";
import { useAsyncAction } from "utils/async-action";
import CheckFormField from "components/input/check-form-field";
import { fetchAddedCodes } from "actions/waste";
import SpinnerPopup from "components/common/spinner-modal";
import { ICode, ICodesState } from "types/waste-types";
import CodeAddressesList from "components/waste/code-addresses-list";
import styles from "./waste-tabs.module.scss";
import { ActionState } from "types/async-types";
import _ from "lodash";
import Waste from "..";
import { WasteCardType } from "types/waste-card-types";

interface IFormValues {
  selected_codes: Array<string>;
}

interface IProps {
  setTransferType: (a: WasteCardType) => any;
}
function WasteList(props: IProps) {
  //** variables */
  const {setTransferType} = props;
  const { submitForm, values } = useFormikContext<IFormValues>();
  const [forwardDisabled, setForwardDisabled] = useState(false);
  

  const [wasteCardsState, execfetchAddedCodes]: readonly [
    ActionState<addedCodesType>,
    any
  ] = useAsyncAction(fetchAddedCodes);
  // const history = useHistory();

  type addedCodesType = {
    [key: string]: ICode;
  };

  //** side effects ********************************/
  useEffect(() => {
    execfetchAddedCodes();
  }, []);

  useEffect(() => {
    if (values.selected_codes.length) {
      const containDangerous = _.some(values.selected_codes, (codeKey) => {
        if (wasteCardsState.data[codeKey].is_dangerous) {
          return true;
        }
      });

      setForwardDisabled(containDangerous);
    }
  }, [values]);

  if (wasteCardsState.loading) {
    return <SpinnerPopup />;
  }

  if (wasteCardsState.data && !Object.keys(wasteCardsState.data).length) {
    return <Alert message="Brak odpadów" />;
  }

  const renderTableItems = (items: addedCodesType) => {
    return Object.keys(items).map((key: any, index: number) => {
      return (
        <tr key={key} className="odd record-137720-row first">
          <td>
            <CheckFormField
              id={`check_${index}`}
              name="selected_codes"
              value={key}
              disabled={items[key].percent == 100}
            />
          </td>
          <td className={`bold waste_code lg-t text-left`}>
            {items[key].code}
          </td>{" "}
          <td className={`${styles.desc} text-left`}>
            <Link to={`${PATH.WASTE_DETAILS}/${key}`}>{items[key].name}</Link>
          </td>{" "}
          <td className={`bold lg-t text-right`}>{items[key].produced} kg</td>{" "}
          <td className={`bold lg-t text-right red`}>
            {items[key].remained} kg
          </td>{" "}
          <td className={`bold lg-t text-right green `}>
            <div className={`${styles.forwarded}`}>
              <span>{items[key].transfered} kg </span>
              <div className={`${styles.forwarded_bar}`}>
                <div
                  className={`${styles.fill}`}
                  style={{ width: `${items[key].percent}%` }}
                ></div>
              </div>
              <span className={`${styles.percent} lg-t`}>
                {items[key].percent} %
              </span>
            </div>
          </td>{" "}
        </tr>
      );
    });
  };

  return (
    <form>
      <div className="fields-group">
        <div className="fields-group-body">
          <table className="list waste-list">
            <thead id="thead-browse">
              <tr>
                <th></th>
                <th className={`text-left`}>Kod odpadu</th>
                <th className={`text-left`}>Nazwa odpadu</th>
                <th className={`text-right`}>Wytworzonych</th>
                <th className={`text-right`}>Nieprzekazanych</th>
                <th className={`text-left`}>Przekazanych</th>
              </tr>
            </thead>
            <tbody id="tbody-browse">
              {wasteCardsState.data
                ? renderTableItems(wasteCardsState.data)
                : ""}
            </tbody>
          </table>
          <div className="buttons mt-10">
            <button
              type="button"
              onClick={() => {setTransferType("company_export") ;submitForm()}}
              disabled={!values.selected_codes.length}
              className="button button-link"
            >
              Przekaż odpad firmie
            </button>
            <button
              type="button"
              onClick={() => {setTransferType("export") ;submitForm()}}
              disabled={!values.selected_codes.length || forwardDisabled}
              className="button button-link"
            >
              Przekaż odpad osobie fizycznej
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const FormWrapper = () => {
  const initialValues: IFormValues = { selected_codes: [] };
  const history = useHistory();
  const [transferType, setTransferType] = useState<WasteCardType>("export");

  const onSubmit = (values: IFormValues) => {
    const codes = values.selected_codes;

    let codesParam = "";
    for (const code of codes) {
      codesParam += `waste_codes[]=${code}&`;
    }
    history.push(`${PATH.WASTE_FORWARD}?${codesParam}&type=${transferType}`);
  };
  const props = {
    setTransferType: setTransferType,
  };
  

  
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      
      <WasteList {...props} />
    </Formik>
  );
};

export default FormWrapper;
