import React, { useState, useEffect } from "react";
import "styles/main.scss";
import styles from "styles/modules/app.module.scss";
import Navbar from "components/layout/navbar";
import Footer from "components/layout/footer";
import Waste from "components/waste/dashboard";
import WasteDetails from "components/waste/waste-details";
import WasteForm from "components/waste/waste-create-form";
import WasteCardEditForm from "components/waste/waste-card/waste-card-edit-form";
import WasteRegisterForm from "components/waste/register-form/register-form";
import WasteForwardForm from "components/waste/waste-forward-form";
import NotFound from "components/common/404";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Redirect, Switch } from "react-router-dom";

import { PATH } from "./path";
import { ToastContainer, toast } from "react-toastify";
import { withAuthSync } from "utils/auth";
import { QueryParamProvider } from "use-query-params";
import {history} from "custom-history";

import "react-toastify/dist/ReactToastify.css";

import { render } from "react-dom";
import RegisterSuccess from "./components/waste/register-form/register-success";

function App(props) {
  const [bgColor, setBgColor] = useState(null);
  useEffect(() => {
    if (window.location.pathname == PATH.WASTE_REGISTER) {
      setBgColor("white_bg");
    }
  }, [window.location.pathname]);

  return (
    <Router history={history}>
      <div id="page-panel">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div id="panel-body">
          <div id="p-header"></div>
          <div id="p-body" className={styles[bgColor]}>
            <Navbar />
            <div id="main-panel">
              <div id="m-body">
                <div id="middle-panel">
                  <Switch>
                    {/* for logged user */}
                    <Route exact path="/" component={withAuthSync(Waste)} />
                    <Route /* withAuthSync in component  */ path={PATH.WASTE_FORWARD}>
                      <WasteForwardForm />
                    </Route>
                    <Route
                      component={withAuthSync(WasteDetails)}
                      path={`${PATH.WASTE_DETAILS}/:code`}
                    />
                    <Route /* withAuthSync in component  */
                      path={PATH.WASTE_CARD_CREATE}
                      component={WasteForm}
                    />
                    <Route
                      component={withAuthSync(WasteCardEditForm)}
                      path={`${PATH.WASTE_CARD_EDIT}/:id`}
                    />
                    <Route
                      path={PATH.WASTE}
                      component={withAuthSync(Waste)}
                    ></Route>
                    {/* for unlogged user */}
                    <Route path={PATH.WASTE_REGISTER}>
                      <WasteRegisterForm />
                    </Route>
                    <Route path={PATH.WASTE_REGISTER_SUCCESS}>
                      <RegisterSuccess />
                    </Route>
                    <Route path="*">
                      <NotFound />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
