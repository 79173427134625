import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import SpinnerPopup from "components/common/spinner-modal";
import {
  stepsValidationSchema,
  initialValues,
} from "form-config/waste-service-config";
import { RootState } from "redux/reducers/index";
import { useHistory } from "react-router-dom";
import { PATH } from "../../../path";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import { signUp } from "actions/user";
import { loginByToken } from "redux/actions/user";
import { fetchFormData } from "actions/company";
import _ from "lodash";
import style from "./register-form.module.scss";
import Step1 from "./step-1";
import Step2 from "./step-2";
import { IRegisterFormData } from "types/waste-types";
import { ActionState } from "types/async-types";


type SignUp = (sid: string) => any;

const RegisterForm = () => {
  //props & hook variables
  const history = useHistory();
  const [stepNo, setStepNo] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [noBDO, setNoBDO] = useState(false);
  const [streetOpts, setStreetOpts] = useState([]);
  const [userLoaded, setUserLoaded] = useState(false);

  const [cityOpts, setCityOpts] = useState([]);
  const user = useSelector((state: RootState) => state.user);

  //** async action hooks */
  const [signUpState, execSignUp] = useAsyncAction(signUp);
  const [userState, execLoginByToken]: readonly [any, SignUp] = useReduxAction(
    (sid: any) => loginByToken(sid),
    [],
    "user"
  );
  // const [authUserState, execAuthUser] = useAsyncAction(authUser);
  const [formData, execFetchFormData] : readonly [ActionState<IRegisterFormData>, typeof fetchFormData] = useAsyncAction(fetchFormData);

  //** side effects */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let SID = urlParams.get("sid");
    if (SID) {
      execLoginByToken(SID);
    } else {
      execFetchFormData();
    }
    history.push({
      pathname: "/waste-register",
      search: `?step=1`,
    });
  }, []);

  useEffect(() => {
    (() => {
      window.addEventListener("popstate", () => onBackClick());
    })();
  }, []);

  useEffect(() => {
    if (signUpState.data) {
      history.push(PATH.WASTE_REGISTER_SUCCESS);
    }
  }, [signUpState.data]);

  useEffect(() => {
    execFetchFormData();
  }, []);

  //** functions */
  const onSubmit = async (values: any) => {
    
    const res = await execSignUp(values);
  };

  const onBackClick = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const stepNo = urlParams.get("step");

    if (window.location.pathname == PATH.WASTE_REGISTER) {
      if (!stepNo) {
        history.back();
      } else {
        setStepNo(parseInt(stepNo));
      }
    }
  };

 


  return (
    <div>
    
      {/* <Alert /> */}
      {/* {stepNo !== 1 && (
        <PageHeader
          title={`Zgłoszenie do ewidencji odpadów - krok ${stepNo}/2`}
        />
      )} */}

      {(isLoading ||
        userState.loading ||
        formData.loading ||
        signUpState.loading) && (
        <SpinnerPopup
          message={signUpState.loading ? "Wysyłanie danych" : null}
        />
      )}

      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={stepsValidationSchema[stepNo]}
        onSubmit={onSubmit}
      >
        {({
          values,
          setFieldValue,
          isValid,
          validateForm,
          setFieldTouched,
          submitForm,
          errors,
        }) => {
            console.log('formik errors: ',errors);
  
          //** function used in both steps (1-st step as auto address preload after company preload, 2-nd step as manual address preload ) */

          const setStep = (stepNo: number) => {
            setStepNo(stepNo);
        
            const urlParams = new URLSearchParams(window.location.search);
            let SID = urlParams.get("sid");
            if(stepNo == 2){
              const wasteCollect = values.waste_collection;
           
              localStorage.removeItem('waste_collect');
              debugger;
              if(wasteCollect[0] == "true"){
                localStorage.setItem('waste_collect', 'true');
                setFieldValue('registration_type', 'administration|pickup')
              }
              else{
                localStorage.setItem('waste_collect', 'false');
                setFieldValue('registration_type', 'administration')
              }
                
            }
            history.push({
              pathname: PATH.WASTE_REGISTER,
              search: `?step=${stepNo}${SID ? `&sid=${SID}` : ""}`,
            });
          };

          const stepProps = {
            noBDO,
            setNoBDO,
            isLoading,
            setIsLoading,
            setStepNo,
            isValid,
            validateForm,
            setFieldValue,
            setFieldTouched,
            values,
            formData,
            streetOpts,
            setStreetOpts,
            cityOpts,
            setCityOpts,
            userLoaded,
            setUserLoaded,
            errors,
          };
          const formButtonsProps = {
            isValid : true,
            validateForm,
            submitForm,
            setStep,
            stepNo,
            formSubmitting:
              isLoading ||
              userState.loading ||
              formData.loading ||
              signUpState.loading,
          };

          

          return (
            <div>
              <Form className={`${style.table_form}`}>
                {(() => {
                  switch (stepNo) {
                    case 1:
                      return (
                        <Step1
                          {...stepProps}
                          
                          formButtonsProps={formButtonsProps}
                          
                        />
                      );
                    case 2:
                      return (
                        <Step2
                          {...stepProps}
                          formButtonsProps={formButtonsProps}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </Form>
              {/* <FormButtons {...formButtonsProps} /> */}
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default RegisterForm;
