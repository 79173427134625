import { useState, useCallback } from "react";
import { isLoading } from "redux/actions/async";
import { useDispatch } from "react-redux";
import { successMessage } from "utils/toaster";
import { handleError } from "utils/http";

//hook for redux action creators (with API calls) - returns asyncAction, loading & error, also sets store-level loaders & error
export const useReduxAction = (
  action: any,
  dependeces = [],
  stateProperty: string
) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(0);

  const asyncAction = useCallback(
    (...args) => {
      async function callback() {
        const loadingData = {
          isLoading: true,
          stateProperty,
          errorCode: 0,
        };
        setLoading(true);
        dispatch(isLoading({ ...loadingData }));

        try {
          const res = await dispatch(action(...args));
         
          dispatch(isLoading({ ...loadingData, isLoading: false }));
          setError(0);
          setLoading(false);
          if (res.successMessage) {
            successMessage(res.successMessage);
          }
          return res;
        } catch (e) {
          if (!e.response) {
            throw e;
          }
          
          dispatch(
            isLoading({
              ...loadingData,
              isLoading: false,
              errorCode: e.response.status,
            })
          );
          setLoading(false);
          setError(e.response.status);
          handleError(e);
          
          return e;
        }
      }

      callback();
    },

    [action, ...dependeces]
  );

  return [{ loading, error }, asyncAction] as const;
};

// hook for regular (non redux) API calls
export const useAsyncAction = (action: any, customError : true | null = null) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any | null>(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState<any>(null);

  const performAction = async (...body: any[]) => {
    try {
      setLoading(true);
      setData(null);
      setError(null);
      const res = await action(...body);
      setData(res.data);
      setResponse(res);
      if (res.successMessage) {
        successMessage(res.successMessage);
      }
      return res;
    } catch (e) {
      
      if (!e.response) {
        throw e;
      }
      setError(e);
      if(!customError){
        handleError(e);
      }
      
    } finally {
      setLoading(false);
    }
  };
  return  [{ data, response, loading, error }, performAction] as const;
};

//regular wrapper function for redux action creators (API) (sets only store-level loaders, errors etc)
export const reduxAction = (action: any, stateProperty = null) => async (
  dispatch: any
) => {
  const loadingData = {
    isLoading: true,
    stateProperty: null,
    errorCode: null,
  };

  dispatch(isLoading({ ...loadingData }));

  try {
    const res = await dispatch(action());
    dispatch(isLoading({ ...loadingData, isLoading: false }));
    if (res.successMessage) {
      successMessage(res.successMessage);
    }
    return res;
  } catch (e) {
    if (!e.response) {
      throw e;
    }

    dispatch(
      isLoading({
        ...loadingData,
        isLoading: false,
        errorCode: e.response.status,
      })
    );
    handleError(e);
    return e;
  }
};
