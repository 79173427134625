import React from "react";
import style from './alert.module.scss';



const Alert = (props: any) => {
    const {message, errorsList, type} = props;
    const renderErrorList =(list: any) => {
      return list.map((item: String,index: Number) => {
        return <div className={style.error}>{item}</div>
      });
    }
    return (
      <div className={`${style.top_message} ${type === 'error' && style.error_message}`} >
        {/* <div className={style.header} >
          
      </div> */}
      {message ? message : renderErrorList(errorsList)}
      </div>
    );
  };

  export default Alert;