import { loginRedirect } from "utils/auth";
import { errorMessage } from "utils/toaster";

export function handleError(error, ctx = null) {
  if (!error || !error.response) {
    errorMessage("Błąd: " + "nieznany błąd");
  } else if (error.response.data.error_message || error.response.data.error_items) {
    const message = error.response.data.error_message;
    const errorItems = error.response.data.error_items;
    if(error.response.status == 401){
      errorMessage("Sesja użytkownika wygasła");
    }else{
      errorMessage("Błąd: " + message, errorItems);
    }
  } 
  else {
    switch (error.response.status) {
      case 401:
        loginRedirect();
        break;
      case 400:
        errorMessage("Błąd: " + "Złe żądanie");
        break;
      case 404:
        errorMessage("Błąd: " + "Podany adres nie istnieje (kod 404)");
        break;
        case 422:
          errorMessage("Błąd: " + "Wprowadzono błędne dane");
          break;
        case 500:
          errorMessage("Błąd: " + "Błąd serwera (kod 500)");
        break;
      default:
        errorMessage("Błąd: " + error.response.data.error);
    }
  }
}
