import {required, minLength, zipCode} from 'validators'
import * as Yup from "yup";

export const addressValidation = {
  postal_code: Yup.string().concat(required),
    aup_type: Yup.string().concat(required),
    street: Yup.string().when("aup_type", {
      is: "-",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().concat(required),
    }),
    post_office : Yup.string().concat(required),
    city: Yup.string().concat(required).nullable(),
    voivodeship: Yup.string().concat(required),
    building_number: Yup.string().concat(required),
};