import React from "react";
// import { Link } from "react-router-dom";
import style from "./page-header.module.scss";

function PageHeader(props) {
    const {title, className, noMargin} = props;
  return (
    <header className={`${style.page_header} ${className}`} style={{marginBottom : noMargin ? 0 : '20px'}} >
        <h1>{title}</h1>
        {props.children}
    </header>   
  );
}

export default PageHeader;
