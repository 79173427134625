import React, { useState, useEffect } from "react";
import { withAuthSync } from "utils/auth";
import { Link } from "react-router-dom";
import PageHeader from "components/common/page-header";
import TableField from "components/input/table-field";
import TextField from "components/input/text-field";
import { Formik } from "formik";
import {
  addedCodesType,
  IWasteForwardFormValues,
  ITransferCode,
  ITransfer,
} from "types/waste-types";
import Alert from "components/common/alert";
import { fetchTransferCodes, createTransferCard } from "actions/waste";
import { fetchAddresses } from "actions/company";
import { useAsyncAction } from "utils/async-action";
import { useHistory } from "react-router-dom";
import { PATH } from "../../path";
import { FIELD_NAMES } from "form-config/waste-card-config";
import SpinnerPopup from "components/common/spinner-modal";
import WasteAddress from "./waste-create-form/waste-address/waste-address";
import styles from "./dashboard/waste.module.scss";
import * as Yup from "yup";
import { dateRequired, required } from "validators";
import _ from "lodash";
import TableTextField from "components/input/table-text-field";
import { WasteCardType } from "types/waste-card-types";

function WasteForward(props: any) {
  interface IForwardedAll {
    [key: string]: {
      [key: string]: boolean;
    };
  }
  const {
    submitForm,
    setFieldValue,
    isValid,
    transferCardState,
    resetForm,
    setFieldTouched,
    validateForm,
    errors,

    setTransferType,
  } = props;
  const { transferType }: { transferType: WasteCardType } = props;
  const { values }: { values: IWasteForwardFormValues } = props;
  const [forwardedAll, setForwardedAll] = useState<IForwardedAll>({});

  const [codesState, execFetchTransferCodes] = useAsyncAction(
    fetchTransferCodes
  );
  const [addressesState, execFetchAddresses] = useAsyncAction(fetchAddresses);
  const history = useHistory();

  const codes: addedCodesType | null = codesState.data;
  // onst message : string = codesState
  const [visibleSections, setVisibleSections] = useState<{
    [key: string]: boolean;
  }>({});

  const applyDate = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let date = values.transfer_date ? values.transfer_date : "";
    // const codes = urlParams.getAll('waste_codes[]');
    // const addressId = urlParams.getAll('waste_codes[]');
    urlParams.set("date", date.replace(/-/gi, "."));
    let paramsString = urlParams.toString();
    paramsString = paramsString.replace(/%5B%5D/gi, "[]");
    paramsString = `?${paramsString}`;

    execFetchTransferCodes(paramsString);

    history.push(`${PATH.WASTE_FORWARD}${paramsString}`);
  };

  const onDateEnter = (e: any) => {
    if (e.key === "Enter") {
      const urlParams = new URLSearchParams(window.location.search);

      // const codes = urlParams.getAll('waste_codes[]');
      // const addressId = urlParams.getAll('waste_codes[]');
      urlParams.set("date", e.target.value.replace(/-/gi, "."));
      let paramsString = urlParams.toString();
      paramsString = paramsString.replace(/%5B%5D/gi, "[]");
      paramsString = `?${paramsString}`;

      execFetchTransferCodes(paramsString);

      history.push(`${PATH.WASTE_FORWARD}${paramsString}`);
    }
  };

  //** side effects */
  useEffect(() => {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    let date = params.get("date");

    if (date) {
      date = date.replace(/\./gi, "-");
    }

    setFieldValue(FIELD_NAMES.WASTE_ADDRESS, params.get("address_id"));
    setFieldValue(FIELD.FORWARD_DATE, date, true);

    let type = params.get("type");
    setFieldValue("card_type", type);
    setTransferType(type);
  }, []);

  useEffect(() => {
    execFetchAddresses();
  }, []);

  useEffect(() => {
    setFieldValue("transfers", {});
    setTimeout(() => validateForm());
  }, [codes]);

  useEffect(() => {
    if (transferCardState.data) {
      history.push(`${PATH.WASTE}?history=true`);
    }
  }, [transferCardState.data]);

  const FIELD = {
    FORWARD_DATE: "transfer_date",
    ITEMS: "items",
    required: true,
  };
  const fieldData = {
    [FIELD.FORWARD_DATE]: {
      name: FIELD.FORWARD_DATE,
      onKeyPress: onDateEnter,
      required: true,
      type: "date",
      label: "Data przekazania odpadu",
      min: "2019-02-18",
    },

    card_number: {
      name: FIELD_NAMES.CARD_NR,
      required: true,
      type: "text",
      label: "Numer karty przekazania odpadu",
    },

    [FIELD_NAMES.WASTE_ADDRESS]: {
      name: FIELD_NAMES.WASTE_ADDRESS,
      type: "text",
      label: "Adres przekazania odpadu",
      // required: false,
      disableDefault: true,
    },
    // [FIELD.FORWARD_AMOUNT]: {
    //   name: FIELD.FORWARD_AMOUNT,
    //   type: "number",
    //   label: "Ile przekazać",
    // },
  };

  const setAmount = (
    code: string,
    addressId: string,
    amount: number | null
  ) => {
    let transfers = values.transfers;

    //   transfers.push({
    //       address_id : addressId,
    //       codes : {
    //         [`${code}`] : {
    //           amount: amount,
    //           normalized_code: code,
    //           unit: "kg",
    //         }
    //       }
    //  })
    const prevCodes = values.transfers[addressId]
      ? values.transfers[addressId].codes
      : null;

    setFieldValue(`transfers[${addressId}]`, {
      codes: {
        ...prevCodes,
        [code]: {
          amount: amount,
          normalized_code: code,
          unit: "kg",
        },
      },
    });
  };

  const toggleSection = (key: string, visible: boolean) => {
    setVisibleSections((prevObj: any) => ({ ...prevObj, [key]: visible }));
  };

  const onAmountChange = (
    e: { target: { value: string } },
    code: string,
    addressId: string
  ) => {
    if (!codes) {
      return;
    }
    const val = parseFloat(e.target.value);

    if (val >= codes[code].addresses[addressId].remained) {
      setForwardedAll({ ...forwardedAll, [code]: { [addressId]: true } });
      if (val > codes[code].addresses[addressId].remained) {
        setAmount(code, addressId, codes[code].addresses[addressId].remained);
        return;
      }
    } else {
      setForwardedAll({
        ...forwardedAll,
        [code]: { ...forwardedAll[code], [addressId]: false },
      });
    }
    setAmount(code, addressId, val);
  };

  const forwardAll = (codeKey: string, addressKey: string) => {
    if (!codes) {
      return;
    }
    setAmount(
      codeKey,
      addressKey,
      codes[codeKey].addresses[addressKey].remained
    );
    // setFieldValue(`transfers[${addressKey}].codes[${codeKey}].amount`, codes[codeKey].addresses[addressKey].remained);
    setForwardedAll({
      ...forwardedAll,
      [codeKey]: { ...forwardedAll[codeKey], [addressKey]: true },
    });
    // values.codes[code].amount = mockCodes[code].produced;
  };

  const onAddressChange = (addressId: string) => {
    setFieldValue(FIELD_NAMES.WASTE_ADDRESS, addressId);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(FIELD_NAMES.WASTE_ADDRESS, addressId);

    let paramsString = urlParams.toString();
    paramsString = paramsString.replace(/%5B%5D/gi, "[]");
    paramsString = `?${paramsString}`;

    execFetchTransferCodes(paramsString);
    history.push(`${PATH.WASTE_FORWARD}${paramsString}`);
  };

  const renderAddresses = (code: ITransferCode, codeKey: string) => {
    const addresses = code.addresses;

    return Object.keys(addresses).map((addressKey: string, index: number) => {
      return (
        <tr
          key={addressKey}
          className={`address-row ${!visibleSections[codeKey] ? "" : "hidden"}`}
        >
          <td className="code"></td>
          <td className={`${styles.bold} address-string text-left`}>
            {addresses[addressKey].address_string}
          </td>{" "}
          {/* <td className={`desc text-left`}>
            <Link to={PATH.WASTE_DETAILS}>{code[addressKey].name}</Link>
          </td>{" "} */}
          <td className={`${styles.forward_input} bold lg-t`}>
            <span className="amount-field ">
              <span className="mr-10">Przekaż</span>
              <TextField
                name={`transfers['${addressKey}'].codes[${codeKey}].amount`}
                type="number"
                min="1"
                max={addresses[addressKey].remained}
                onChange={(e: { target: { value: string } }) =>
                  onAmountChange(e, codeKey, addressKey)
                }
              />
              kg z dostępnych
            </span>

            <span className="red">{addresses[addressKey].remained} kg </span>
          </td>{" "}
          <td className={`bold lg-t text-right red`}>
            <button
              className={`link ${
                (forwardedAll[codeKey]
                  ? forwardedAll[codeKey][addressKey]
                  : false) && "disabled"
              }`}
              disabled={
                (forwardedAll[codeKey]
                  ? forwardedAll[codeKey][addressKey]
                  : false) && true
              }
              onClick={() => forwardAll(codeKey, addressKey)}
            >
              Wszystko
            </button>
          </td>{" "}
          {/* <td className={`bold link text-right`}>
            
          </td> */}
        </tr>
      );
    });
  };

  const FilteredCodes = () => {
    if (!codes) {
      return <div></div>;
    }

    if (codes.message) {
      return <Alert message={codes.message} />;
    }

    const codesList = Object.keys(codes).map((key: string, index: number) => {
      return [
        <tr className="code-row">
          <td className="lg-t text-left code">{codes[key].code}</td>
          <td className="lg-t name desc">{codes[key].name}</td>
          <td className="lg-t total-amount">{codes[key].total_remained} kg</td>
          <td className={`text-right action-btn`}>
            {!visibleSections[key] ? (
              <button
                onClick={() => toggleSection(key, true)}
                className="button inverted-button border visible"
              >
                Zwiń
              </button>
            ) : (
              <button
                onClick={() => toggleSection(key, false)}
                className="button inverted-button border hidden"
              >
                Rozwiń
              </button>
            )}
          </td>
        </tr>,
        renderAddresses(codes[key], key),
      ];
    });

    // const codesList = items.map((key: string, index: number) => {
    //   let addressList = Object.keys(codes[key]).map((addressKey : string, addressIndex : number) => {
    //     return (
    // <tr key={key} className="odd record-137720-row first">
    //   <td className={`${styles.bold} ${styles.waste_code} lg-t text-left`}>
    //     {codes[key][addressKey].code}
    //   </td>{" "}
    //   <td className={`desc text-left`}>
    //     <Link to={PATH.WASTE_DETAILS}>{codes[key][addressKey].name}</Link>
    //   </td>{" "}
    //   <td className={`${styles.forward_input} bold lg-t`}>
    //     <TextField
    //       name={`codes['${key}'].amount`}
    //       type="number"
    //       min="1"
    //       max={codes[key][addressKey].remained}
    //       onChange={(e: { target: { value: string } }) =>
    //         onAmountChange(e, key, addressKey)
    //       }
    //     />
    //     kg
    //   </td>{" "}
    //   <td className={`bold lg-t text-right red`}>
    //     {codes[key][addressKey].remained} kg
    //   </td>{" "}
    //   <td className={`bold link text-right`}>
    //     <button
    //       className={`link ${forwardedAll[key] && "disabled"}`}
    //       disabled={forwardedAll[key] && true}
    //       onClick={() => forwardAll(key, addressKey)}
    //     >
    //       Wszystko
    //     </button>
    //   </td>
    // </tr>
    //     );

    //   });
    //   return addressList;

    // });

    return (
      <>
        <table className="list waste-list">
          <thead>
            <tr>
              <th className={`text-left code`}>Kod odpadu</th>
              <th className={`text-left name desc`}>Nazwa</th>
              <th className={`text-left total-amount`}>Łącznie dostępnych</th>
              <th className={`text-left total-amount`}></th>
            </tr>
          </thead>
          <tbody>{codesList}</tbody>
        </table>
      </>
    );
  };

  const addressModalProps = {
    adding_disabled: true,
    empty_allowed: true,
    setFieldValue,
    onChange: onAddressChange,
    values,
    wasteCardMode: true,
    addressesState,
    addressField: fieldData[FIELD_NAMES.WASTE_ADDRESS],
  };

  if (codesState.loading) {
    return <SpinnerPopup />;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const date = urlParams.get("date");

  return (
    <div>
      {transferType == "company_export" ? (
        <PageHeader title="Przekazanie odpadu firmie" />
      ) : (
        <PageHeader title="Przekazanie odpadu osobie fizycznej" />
      )}

      <p>
        Wypełnij datę przekazania, a następnie podaj przekazywane ilości. System
        uwzględniając datę przekazania przelicza ile maksymalnie można
        przekazać. Dostępne są ilości wytworzone przed datą przekazania.
      </p>
      <table className="details  mb-20" cellSpacing="1">
        <tbody>
          {transferType == "company_export" && (
            <TableTextField {...fieldData.card_number} />
          )}

          <TableField {...fieldData[FIELD.FORWARD_DATE]}>
            <div className="flex">
              <div className="grow-1">
                <TextField {...fieldData[FIELD.FORWARD_DATE]} />
              </div>
              <div>
                <button
                  disabled={!values.transfer_date || errors.transfer_date}
                  onClick={() => applyDate()}
                  className="button primary-button ml-10"
                >
                  Zastosuj
                </button>
              </div>
            </div>
          </TableField>
          {values.transfer_date && date && (
            <WasteAddress {...addressModalProps} />
          )}
        </tbody>
      </table>
      {codes && !Object.keys(codes).length ? (
        <Alert message="Brak wyników" />
      ) : (
        FilteredCodes()
      )}
      <div className="buttons right">
        <button
          disabled={!isValid}
          type="button"
          onClick={submitForm}
          className="button"
        >
          Przekaż
        </button>
      </div>
    </div>
  );
}

/* Form wrapper with config and basic actions & props */
const FormWrapper = (props: any) => {
  const [transferType, setTransferType] = useState<string | null>(null);
  const [transferCardState, execCreateTransferCard] = useAsyncAction(
    createTransferCard
  );
  const submitAction: (
    a: IWasteForwardFormValues
  ) => Promise<any> = execCreateTransferCard;

  const validateTransfers = Yup.object()
    .nullable()
    .test(
      "transfers",
      "Należy dodać przynajmniej jeden kod odpadu",
      (transfers: any) => {
        let isValid = true;
        if (!transfers || !Object.keys(transfers).length) {
          return false;
        }
        // return true;
        _.map(Object.keys(transfers), (key) => {
          if (!Object.keys(transfers[key].codes).length) {
            isValid = false;
          }
          isValid = _.some(Object.keys(transfers[key].codes), (codeKey) => {
            return (
              transfers[key].codes[codeKey].amount !== 0 &&
              transfers[key].codes[codeKey].amount !== undefined
            );
          });
          //   values[key].amount !== null && values[key].amount !== undefined
          // );
        });
        return isValid;
      }
    );

  //** validation schema */
  const validationSchema = Yup.object().shape({
    card_number:
      transferType == "company_export"
        ? Yup.string().concat(required)
        : Yup.string(),
    transfer_date: Yup.date()
      .concat(dateRequired)
      .max(
        new Date(),
        "Podana data nie może być późniejsza niż data dzisiejsza"
      ),
    transfers: validateTransfers,
  });

  const onSubmit = (values: IWasteForwardFormValues) => {
    submitAction(values);
  };

  const formConfig = {
    onSubmit: onSubmit,
    initialValues: { address_id: undefined, transfers: {}, card_type: null },
    validationSchema: validationSchema,
    isInitialValid: false,
    enableReinitialize: true,
  };

  return (
    <Formik {...formConfig}>
      {(formikProps) => {
        return (
          <WasteForward
            {...props}
            {...formikProps}
            transferType={transferType}
            setTransferType={setTransferType}
            transferCardState={transferCardState}
          />
        );
      }}
    </Formik>
  );
};

export default withAuthSync(FormWrapper);
