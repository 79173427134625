import React from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import style from "./register-success.module.scss";

const RegisterSuccess = (props) => {
  return (
    <div className={style.success_container}>
      <h1 className="mb-30">Administracja i sprawozdawczość</h1>
      <div className={style.success_box}>
        <CheckCircleOutlineIcon className={style.success_icon} />
        <h1>Zgłoszenie wysłane</h1>
        <p>
        Na podany adres email zostanie Ci wysłana informacja o dalszych krokach.
        </p>
      </div>
    </div>
  );
};

export default RegisterSuccess;
