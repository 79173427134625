import axios, {AxiosRequestConfig} from "axios";
import { API_URL } from "config";
import {requestHeaders} from 'config/headers'
import {IReportFormValues, ISubmitReport, IGetReport} from 'types/report-types'
import _ from 'lodash'

export const getReport : IGetReport =  async (year : string) => {

    const options: AxiosRequestConfig = {
        url: `${API_URL}/year_reports/report_data?year=${year}`,
        method: "GET",
        headers: requestHeaders()
      };
      let res = await axios(options);
      return res;
}


export const submitReport : ISubmitReport =  async (email : string | null) => {
  let data : any = {};
    if(email){
      data.email = email; 
    }
    const options: AxiosRequestConfig = {
        url: `${API_URL}/year_reports`,
        method: "POST",
        data: data,
        headers: requestHeaders()
      };
      let res = await axios(options);
      const result = {...res , successMessage : 'Sprawozdanie zostało wysłane'}
      return result;
}