import React, { useEffect, useState } from "react";
import TableFields from "components/input/table-fields";
import { Field, ErrorMessage, getIn, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import TableField from "components/input/table-field";
import TextField from "components/input/text-field";
import SelectField from "components/input/select-field";
import { ErrorMsg, renderCheckbox } from "components/input/table-fields";
import CheckFormField from "components/input/check-form-field";
import { useHistory } from "react-router-dom";
import FormButtons from "./form-buttons";
import { zipCodeRegExp, customRequired, customCheckRequired } from "validators";
import InputMask from "react-input-mask";
import { renderTextWithLinks, IText } from "utils/helpers";

import { useReduxAction, useAsyncAction } from "utils/async-action";
import {
  address1Fields,
  BDOField,
  nipField,
  FIELD,
  basicFields,
} from "form-config/waste-service-config";
import Alert from "components/common/alert";
import { nipRegExp, regonRegExp } from "validators/index";
import {
  checkPostalCode,
  fetchCompanyByTax,
  checkREGON,
} from "../../../actions/company";
import _ from "lodash";
import { PATH } from "../../../path";
import { replaceProps } from "utils/helpers";
import style from "./register-form.module.scss";
import { IRegisterFormData } from "types/waste-types";
import { ActionState } from "types/async-types";
import { errorMessage } from "utils/toaster";

const RegistertFormStep3 = (props: any) => {
  //** props & hook variables */

  const history = useHistory();
  const { submitForm } = useFormikContext();
  const user = useSelector((state: any) => state.user);
  const [streetDisabled, setStreetDisabled] = useState(false);
  const [topMessage, setTopMessage] = useState<string | null>(null);

  const [nipError, setNipError] = useState(null);
  const [regonError, setRegonError] = useState(null);
  const [forcedNip, setForcedNip] = useState(false);
  const [forcedRegon, setForcedRegon] = useState(false);
  const [postalOfficeOpts, setPostalOfficeOpts] = useState<any>([]);
  const {
    setStepNo,
    address2Disabled,
    setAddress2Disabled,
    setFieldValue,
    setIsLoading,
    isValid,
    validateForm,
    values,

    streetOpts,
    noBDO,

    isLoading,
    setFieldTouched,
    setNoBDO,
    formButtonsProps,
    cityOpts,
    setCityOpts,
    setStreetOpts,
    userLoaded,
    setUserLoaded,
  } = props;

  const { formData }: { formData: ActionState<IRegisterFormData> } = props;

  const formErrors = props.errors;

  // const [addressState, execFetchAddress] = useAsyncAction(fetchAddress);
  const [postalCodeData, execCheckPostalCode] = useAsyncAction(checkPostalCode);
  const [companyData, execFetchCompanyByTax] = useAsyncAction(
    fetchCompanyByTax,
    true
  );
  const [REGONData, execCheckREGON] = useAsyncAction(checkREGON, true);

  function setValueIfEmpty(fieldName: any, newVal: any) {
    const val = getIn(values, fieldName);

    if (!val) {
      setFieldValue(fieldName, newVal);
    }
  }

  const policy_privacy = {
    text: "regulamin #reg# i #policy#",
    extras: {
      reg: {
        link: "https://google.com",
        text: "Google",
      },
      policy: {
        link: "https://cts24.pl",
        text: "Prywatność",
      },
    },
  };

  const testFields = {
    name: FIELD.CITY,
    type: "select-autocomplete",
    label: "Miejscowość",
    required: true,
    options: cityOpts ? cityOpts : [{ label: "", value: "" }],
    value: values.address.city
      ? { value: values.address.city, label: values.address.city }
      : null,
    onChange: (option: any) => {
      if (option) {
        setFieldValue(FIELD.CITY, option.value);
      } else {
        setFieldValue(FIELD.CITY, "");
      }
    },
  };

  /** functions */
  const onRegonChange = (event: any) => {
    const fieldName = event.currentTarget.name;
    const fieldValue = event.target.value;
    setFieldValue(fieldName, fieldValue);
    if (regonRegExp.test(fieldValue)) {
      setIsLoading(true);
      setTimeout(async function () {
        setIsLoading(false);
        await execCheckREGON(fieldValue);
      }, 500);
    }
  };

  const preloadAddress = (event: any) => {
    const text = event.target.value;
    setFieldValue(FIELD.ZIP_CODE, event.target.value);
    setFieldTouched(FIELD.ZIP_CODE, true, false);
    if (zipCodeRegExp.test(text)) {
      setTimeout(async function () {
        setIsLoading(true);
        setStreetOpts([]);
        setCityOpts([]);
        const res = await execCheckPostalCode(text);
        if (res && res.data.length) {
          res.data.map((item: any, index: any) => {
            setStreetOpts((oldArray: any) => [
              ...oldArray,
              { value: item.street, label: item.street },
            ]);

            setCityOpts((oldArray: any) => [
              ...oldArray,
              { value: item.city, label: item.city },
            ]);

            setPostalOfficeOpts((oldArray: any) => [
              ...oldArray,
              { value: item.post_office, label: item.post_office },
            ]);
          });

          setCityOpts((oldArray: any) => _.uniqWith(oldArray, _.isEqual));
          setPostalOfficeOpts((oldArray: any) =>
            _.uniqWith(oldArray, _.isEqual)
          );

          setFieldValue("address.voivodeship", res.data[0].voivodeship);
        }else{
          errorMessage("Brak rezultatu dla podanego kodu. Upewnij się że podałeś prawidłowy kod pocztowy.")
        }
        // else{
        //   errorMessage('Brak danych adresowych dla podanego kodu')
        // }
        setIsLoading(false);
      }, 500);
    }
  };

  const setSelectField = (fieldName: string, value: any, setOpts: any) => {
    if (value) {
      setOpts([{ value: value, label: value }]);
      setValueIfEmpty(fieldName, value);
      setTimeout(() => setFieldTouched(fieldName, true));
    }
  };

  useEffect(() => {
    if (Object.keys(user).length && !userLoaded) {
      setIsLoading(true);
      replaceProps(null, "", user.client);
      replaceProps(null, "", user.address);
      setValueIfEmpty(FIELD.NIP, user.client.tax_number);
      setValueIfEmpty(FIELD.COMPANY_NAME, user.client.company_name);
      setValueIfEmpty(FIELD.REGON, user.client.regon_number);
      setValueIfEmpty(FIELD.BDO_NR, user.client.bdo_number);
      setValueIfEmpty(FIELD.EMAIL, user.client.email);
      setValueIfEmpty(FIELD.PHONE, user.client.phone);
      setValueIfEmpty(FIELD.LEGAL_FORM, user.client.legal_form);
      setValueIfEmpty(FIELD.AUP_TYPE, user.address.aup_type);
      setValueIfEmpty("client.cbk_id", user.client.cbk_id);
      setValueIfEmpty(FIELD.ZIP_CODE, user.address.postal_code);

      setSelectField(FIELD.CITY, user.address.city, setCityOpts);
      setSelectField(FIELD.STREET, user.address.street, setStreetOpts);
      setSelectField(
        FIELD.POST_OFFICE,
        user.address.post_office,
        setPostalOfficeOpts
      );

      setValueIfEmpty(FIELD.BUILDING_NR, user.address.building_number);
      setValueIfEmpty(FIELD.LOCUM_NR, user.address.locum_number);
      setValueIfEmpty(FIELD.VOIVODESHIP, user.address.voivodeship);
      setUserLoaded(true);
      setIsLoading(false);
    }
  }, user);

  const preloadCompany = (event: any) => {
    // const text = event.target.value;
    const fieldName = event.currentTarget.name;
    const fieldValue = event.target.value;
    setFieldValue(fieldName, fieldValue);
    if (nipRegExp.test(fieldValue)) {
      setTimeout(async function () {
        setIsLoading(true);
        const res = await execFetchCompanyByTax(fieldValue);

        setIsLoading(false);

        if (res) {
          if (res.data.status == "NEW" || res.data.status == "ACCEPTED") {
            // history.push(PATH.WASTE_REGISTER_SUCCESS);
            setTopMessage(
              "Klient z podanym numerem NIP wysłał już zgłoszenie. Wpisz inny NIP."
            );
          } else {
            setTopMessage(null);

            replaceProps(null, "", res.data.client);
            replaceProps(null, "", res.data.address);
            setValueIfEmpty(FIELD.COMPANY_NAME, res.data.client.company_name);
            setValueIfEmpty(FIELD.REGON, res.data.client.regon_number);
            setValueIfEmpty(FIELD.BDO_NR, res.data.client.bdo_number);
            setValueIfEmpty(FIELD.EMAIL, res.data.client.email);
            setValueIfEmpty(FIELD.PHONE, res.data.client.phone);
            setValueIfEmpty(FIELD.LEGAL_FORM, res.data.client.legal_form);
            setValueIfEmpty("client.cbk_id", res.data.client.cbk_id);
            setValueIfEmpty(FIELD.ZIP_CODE, res.data.address.postal_code);

            setCityOpts([
              { value: res.data.address.city, label: res.data.address.city },
            ]);
            setValueIfEmpty(FIELD.CITY, res.data.address.city);
            setTimeout(() => setFieldTouched(FIELD.CITY, true));

            setStreetOpts([
              {
                value: res.data.address.street,
                label: res.data.address.street,
              },
            ]);
            setValueIfEmpty(FIELD.STREET, res.data.address.street);
            setTimeout(() => setFieldTouched(FIELD.STREET, true));

            const postOffice = res.data.address.post_office;
            setPostalOfficeOpts([
              {
                value: postOffice,
                label: postOffice,
              },
            ]);
            setValueIfEmpty(FIELD.POST_OFFICE, postOffice);
            setTimeout(() => setFieldTouched(FIELD.POST_OFFICE, true));

            setValueIfEmpty(
              FIELD.BUILDING_NR,
              res.data.address.building_number
            );
            setValueIfEmpty(FIELD.LOCUM_NR, res.data.address.locum_number);
            setValueIfEmpty(FIELD.VOIVODESHIP, res.data.address.voivodeship);
            // preloadAddress({ target: { value: res.data.address.postal_code } });
          }
        }
      }, 500);
    }
  };

  const onForceNipChange = (e: any) => {
    const checked = e.target.checked;
    setForcedNip(checked);
  };

  const onForceRegonChange = (e: any) => {
    const checked = e.target.checked;
    setForcedRegon(checked);
  };

  const parseLegalForms = () => {
    if (formData.data) {
      const legalForms = formData.data.legal_forms;
      const options = _.map(legalForms, (item, index) => {
        return { value: index, label: item };
      });
      return options;
    }
    return [];
  };

  const onBdoCbxChange = () => {
    setFieldValue(FIELD.BDO_NR, "");
    setTimeout(() => setFieldTouched(FIELD.BDO_NR, true));
    setNoBDO(!noBDO);
  };

  //** side effects */
  useEffect(() => {
    if (REGONData.error && !forcedRegon) {
      setRegonError(REGONData.error?.response.data.error_message);
    } else {
      setRegonError(null);
    }
  }, [REGONData]);

  useEffect(() => {
    if (companyData.error && !forcedNip) {
      setNipError(companyData.error.response.data.error_message);
    } else {
      setNipError(null);
    }
  }, [companyData]);

  useEffect(() => {
    (() => validateForm())();
  }, []);

  useEffect(() => {
    if (values.address.aup_type == "-") {
      setStreetDisabled(true);
      setFieldValue("address.street", "");
    } else {
      setStreetDisabled(false);
    }
  }, [values.address.aup_type]);

  const addressFieldsData = address1Fields({
    streetDisabled,
    streetOpts,
    cityOpts,
    postalOfficeOpts,
    preloadAddress,
    setFieldValue,
    values,
  });
  const BDOFieldData = BDOField({ noBDO });
  const nipFieldData = nipField({ preloadCompany });
  const basicFieldsData = basicFields({
    parseLegalForms,
    onRegonChange,
    onForceRegonChange,
    onForceNipChange,
  });
  // const basicFieldsData = _.values(basicFields({ parseLegalForms }));
  const errors = [nipError, regonError];

  const zipCodeField = {
    name: FIELD.ZIP_CODE,
    type: "text",
    label: "Kod pocztowy",
    required: true,
    onChange: preloadAddress,
  };

  const funct = (requiredVals: string[], values: string[]) => {
    debugger;
    if(requiredVals.every(v => values.includes(v))){
      return undefined
     
    }
    else{
      return "To pole jest wymagane";
    }
 
  };

  const requiredVals : string[] = [];

  const renderCheckboxesSection = (
    checkboxes: { [key: string]: IText },
    group: "agreements" | "pickup"
  ) => {
    if (formData.data) {
   
      return Object.keys(checkboxes).map((key, index) => {
        const val = key;
        if(checkboxes[key].is_required){
          requiredVals.push(key);
        }
        return <div className="mb-10">
          <CheckFormField
            required={checkboxes[key].is_required}
            doubleRequired={
              group == "pickup" ? checkboxes[key].is_required : false
            }
            validate={
              checkboxes[key].is_required
                ? (value: string[]) => funct(requiredVals, value)
                : undefined
            }
            
            id={key}
            value={key}
            name="agreements"
            cbxLabel={renderTextWithLinks(checkboxes[key])}
          />
          {(!values.agreements.includes(key) && checkboxes[key].is_required) &&
            <ErrorMessage name={"agreements"} render={(msg) => ErrorMsg(msg) } />
          }
          </div>
          
          
       
      });
    }
  };

  return (
    <div data-testid="register-step-2">
      <h1 className={`mb-30`}>
        Formularz zgłoszenia do usługi administracji i sprawozdawczości
      </h1>
      {topMessage && <Alert message={topMessage} />}
      {(regonError || nipError) && <Alert type="error" errorsList={errors} />}

      <div className="row">
        <div className="col-8">
          <h2>Dane firmy</h2>
          <table className="details simple" cellSpacing="1">
            <tbody>
              {/* <TableField {...testFields}>
                <SelectField {...testFields}></SelectField>
              </TableField> */}

              <TableField {...nipFieldData}>
                <div>
                  <TextField {...nipFieldData}></TextField>
                  <div className="mt-10">
                    {renderCheckbox(basicFieldsData[FIELD.FORCE_NIP])}
                  </div>
                </div>
              </TableField>

              <TableField {...basicFieldsData[FIELD.REGON]}>
                <div>
                  <TextField {...basicFieldsData[FIELD.REGON]}></TextField>
                  <div className="mt-10">
                    {renderCheckbox(basicFieldsData[FIELD.FORCE_REGON])}
                  </div>
                </div>
              </TableField>

              <tr key={2} className="p5-row">
                <td
                  className={`caption ${BDOFieldData.required && "mandatory"}`}
                >
                  {BDOFieldData.label}
                </td>
                <td className="value">
                  <Field className="text" {...BDOFieldData}></Field>
                  {!noBDO && (
                    <ErrorMessage name={BDOFieldData.name} render={ErrorMsg} />
                  )}

                  <CheckFormField
                    name="client.no_bdo"
                    className="mt-10"
                    value="true"
                    id="no_bdo"
                    onCheckChange={onBdoCbxChange}
                    cbxLabel="Nie posiadam numeru BDO"
                  />
                  <ErrorMessage name="client.no_bdo" render={ErrorMsg} />
                </td>
              </tr>
              <TableField {...basicFieldsData[FIELD.COMPANY_NAME]}>
                <TextField {...basicFieldsData[FIELD.COMPANY_NAME]}></TextField>
              </TableField>
              <TableField {...basicFieldsData[FIELD.LEGAL_FORM]}>
                <SelectField
                  {...basicFieldsData[FIELD.LEGAL_FORM]}
                  options={basicFieldsData[FIELD.LEGAL_FORM].options}
                />
              </TableField>
            </tbody>
          </table>
        </div>
        <div className="col-8">
          <h2>Adres firmy</h2>
          <table className="details simple" cellSpacing="1">
            <tbody>
              <TableField {...zipCodeField}>
                <div>
                  <Field {...zipCodeField}>
                    {({ field, form }: any) => {
                      return (
                        <>
                          <InputMask
                            className="text"
                            {...zipCodeField}
                            mask="99-999"
                          />
                        </>
                      );
                    }}
                  </Field>
                  <ErrorMessage name={zipCodeField.name} render={ErrorMsg} />
                </div>
              </TableField>
              <TableFields fields={addressFieldsData} />
            </tbody>
          </table>
        </div>
        <div className="col-8">
          {" "}
          <h2>Dane kontaktowe </h2>
          <table className="details simple" cellSpacing="1">
            <tbody>
              <TableField {...basicFieldsData[FIELD.PHONE]}>
                <TextField {...basicFieldsData[FIELD.PHONE]}></TextField>
              </TableField>
              <TableField {...basicFieldsData[FIELD.EMAIL]}>
                <TextField {...basicFieldsData[FIELD.EMAIL]}></TextField>
              </TableField>
            </tbody>
          </table>
        </div>

        {/* <div className="col-half">
          <h2>Miejsce prowadzenia działalności</h2>
          <CheckFormField
            onCheckChange={onCheckChange}
            className={style.header_checkbox}
            cbxLabel="Inne niż adres firmy"
            name="other_address"
            value="true"
          />
          <table className="details" cellSpacing="1">
            <tbody>
              <TableFields fields={address2Fields} />
            </tbody>
          </table>
        </div> */}
      </div>
      <div>
        {/* {renderTextWithLinks(policy_privacy)} */}
        {formData.data &&
          renderCheckboxesSection(
            formData.data.agreements.administration,
            "agreements"
          )}
        <p className={`${style.required_note} mb-20`}>
          <span className={`green ${style.required} `}>*</span> - Pola oznaczone
          gwiazdką są obowiązkowe
        </p>
        {localStorage.getItem("waste_collect") == "true" && (
          <>
            {renderCheckboxesSection(formData.data.agreements.pickup, "pickup")}
            <p className={`${style.required_note} mb-20`}>
              <span className={`green ${style.required} `}>**</span> - Pola
              oznaczone gwiazdką są obowiązkowe przy wyborze pola{" "}
              <b>Odbiór odpadów </b>
            </p>
          </>
        )}

        {/* {formData.data && } */}
        {/* <table className="details simple" cellSpacing="1">
          <tbody>
              <CheckFormField id="name1" name="name1" cbxLabel="this is label" />
          
          </tbody>
        </table> */}
      </div>
      {/* <div className="buttons">
        <a className="button button-link" onClick={() => goBack(1)}>
          Poprzedni krok
        </a>
        <button disabled={!isValid} className="button button-link">
          Zarejestruj
        </button>
      </div> */}

      <FormButtons
        {...formButtonsProps}
        disabled={
          topMessage ||
          nipError ||
          regonError ||
          formButtonsProps.formSubmitting
        }
        onClick={(values: any) => {
          setIsLoading(true);
          if (Object.keys(formErrors).length > 0) {
            errorMessage(
              "Sprawdź podświetlone pola. Pola oznaczone znakiem * są obowiązkowe"
            );
          }
          setIsLoading(false);
          submitForm();
        }}
        position="center"
      />
    </div>
  );
};

export default RegistertFormStep3;
