import React, { Component } from "react";
// import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Field, ErrorMessage } from "formik";
import inputStyle from "./table-fields.module.scss";

interface IProps {
  disabled?: boolean;
  name: string;
  disableErrorMessage?: boolean;
  type? : string
  min? : string | number
  max? : string | number
  onChange? : (a: any) => void
  validate? : (a: any) => void
}

const ErrorMsg = (msg: string) => (
  <span className={inputStyle.validation_error}>{msg} </span>
);

function TextField(props: IProps) {
  const { disabled, name, disableErrorMessage } = props;

  return (
    <>
      <Field data-testid="textfield" className={`text`} {...props} disabled={disabled}></Field>
      {!disableErrorMessage && <ErrorMessage name={name} render={ErrorMsg} />}
    </>
  );
}

export default TextField;
