import React from "react";
import styles from "./pagination.module.scss";
import Pagination from "@material-ui/lab/Pagination";
const Pages = ({
  count,
  onPageChange,
}: {
  count: number;
  onPageChange: (e: object, page: number) => void;
}) => {
  return (
    <div className={styles.pagination}>
      <div className={styles.pagination_wrapper}>
        <Pagination
          onChange={(e: object, page: number) => onPageChange(e, page)}
          count={count}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default Pages;
