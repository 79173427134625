import React from "react";


type IProps = {
  label?: string,
  required?: boolean,
  children : JSX.Element
}
function TableField(props : IProps) {
  const { label, required } = props;

  return (
    <tr className="p5-row">
      <td data-testid="table-field-label" className={`caption ${required && "mandatory"}`}>{label}</td>
      <td className="value">{props.children}</td>
    </tr>
  );
}

export default TableField;
