import React, { Component } from "react";
import style from "./navbar.module.scss";

function Footer() {
  return (
    <div id="footer-panel">
      <div id="f-header"></div>
      <div id="f-body">
      <div id="company-info">
	Integrator sp. z o.o.<br/>biuro@integrator.org.pl<br/>Tel.: 17 870 02 30</div>
        <div id="author-info">
          <div class="scope">
            <a href="http://www.scripting.pl">projekt i realizacja</a>
          </div>
          <a href="http://www.scripting.pl">
            <img
              src="/images/scripting-logo.png"
              alt="Agencja interaktywna Scripting"
              title="Systemy informatyczne | Agencja interaktywna Scripting | www.scripting.pl"
            />
          </a>
        </div>
        <div class="cleaner-box"></div>
      </div>
      <div id="f-footer"></div>
    </div>
  );
}

export default Footer;
