import React, { useEffect, useState } from "react";
import { PATH } from "../../../../path";
import { Link } from "react-router-dom";
import TableField from "components/input/table-field";
import TextField from "components/input/text-field";
import SelectField from "components/input/select-field";
import { Formik, useFormikContext } from "formik";
import { formatDate } from "utils/date";
import { useAsyncAction } from "utils/async-action";
import {
  fetchCardsHistory,
  deleteTransferCard,
  deleteWasteCard,
} from "actions/waste";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import { ICardSearchForm, ICardItem, IHistory } from "types/waste-types";
import SpinnerPopup from "components/common/spinner-modal";
import { ConfirmAlert } from "components/common/confirm-alert";
import Alert from "components/common/alert";
import styles from "./waste-tabs.module.scss";

function WasteList(props: any) {
  //** hooks ,props & variables */
  const {
    values,
    submitForm,
    cardsHistory,
    execFetchCardsHistory,
    setFieldValue,
  } = props;

  const [transferCardState, execDeleteTransferCard] = useAsyncAction(
    deleteTransferCard 
  );
  const [wasteCardState, execDeleteWasteCard] = useAsyncAction(deleteWasteCard);

  const FIELD = {
    OPERATION_TYPE: "operation_type",
    CARD_ID: "card_number",
  };

  const refreshResult = (e: any, fieldName: string) => {
    if (e.key == "Enter") {
      submitForm();
    }
  };

  const onOperationChange = (e: any, fieldName: string) => {
    setFieldValue(fieldName, e.target.value);
    submitForm();
  };
  const operation_type = {
    name: FIELD.OPERATION_TYPE,
    type: "select",
    label: "Pokaż operacje",
    disableDefault: true,
    onChange: (e: any) => onOperationChange(e, FIELD.OPERATION_TYPE),
    // onKeyPress: (e: any) => refreshResult(e, FIELD.OPERATION_TYPE),
    options: [
      { value: "all", label: "Wszystkie" },
      { value: "export", label: "Przekazanie" },
      { value: "import", label: "Przyjęcie" },
    ],
  }
  //** Form fields */
  const formFields  = {
   
    card_number: {
      name: FIELD.CARD_ID,

      onKeyPress: (e: any) => refreshResult(e, FIELD.OPERATION_TYPE),
      type : 'text',
      label: "Wyszukaj wpis",
      placeholder: "Nr karty / identyfikator przekazania",
    },
  };

  //** side effects ********************************/
  useEffect(() => {
    if (wasteCardState.data) {
      execFetchCardsHistory();
    }
  }, [wasteCardState.data]);

  useEffect(() => {
    if (transferCardState.data) {
      execFetchCardsHistory();
    }
  }, [transferCardState.data]);

  useEffect(() => {
    execFetchCardsHistory();
  }, []);

  /** functions  *********************************/
  const deleteItem = (operationType: string, cardNumber: string) => {
    switch (operationType) {
      case "import":
        ConfirmAlert(() => execDeleteWasteCard(cardNumber));
        break;
      case "import_export":
        ConfirmAlert(() => execDeleteWasteCard(cardNumber));
        break;
      case "export":
        ConfirmAlert(() => execDeleteTransferCard(cardNumber));
        break;
    }
  };

  const renderTableItems = (items: Array<ICardItem>) => {
    const operations = {
      export: "Przekazanie",
      import: "Przyjęcie",
      import_export: "Przekazanie",
    };
    return items.map((item: ICardItem, index: number) => {
      let appendix = "";
      if (item.operation_type == "export") {
        appendix = "POF: ";
      } else if (item.operation_type == "import_export") {
        appendix = "KPO: ";
      }
      return (
        <tr key={index} className="odd record-137720-row first">
          <td className={`bold text-left`}>{item.created_at}</td>{" "}
          <td className={`text-left`}>
            {/* <img src="/images/circle-off-outline.svg" /> */}
            <div className={styles.status}>
              {/* <img src="/images/checkbox-marked.svg" /> */}
              <div>
                {/* <div className="green">Przekazane</div> */}
                <div>{item.card_number != "" && `${appendix}${item.card_number}`}</div>
              </div>
            </div>
          </td>{" "}
          <td className={`text-left`}>{item.manufacture_date}</td>{" "}
          <td className={`text-left`}>{formatDate(item.transfer_date)}</td>{" "}
          <td className={`text-left`}>{item.address}</td>{" "}
          <td>
            <span
              className={`${
                item.operation_type === "import_export" ||
                item.operation_type === "export"
                  ? "green"
                  : ""
              }`}
            >
              {" "}
              {item.operation_type && operations[item.operation_type]}
            </span>
          </td>
          <td>
            <div className={`actions`}>
              {!item.read_only && (
                <>
                  <Link to={`${PATH.WASTE_CARD_EDIT}/${item.id}`}>
                    <EditIcon className={`edit`} />{" "}
                  </Link>
                  <ClearIcon
                    onClick={() => deleteItem(item.operation_type, item.id)}
                  />
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };
  if (
    cardsHistory.loading ||
    wasteCardState.loading ||
    transferCardState.loading ||
    !cardsHistory.response
  ) {
    return <SpinnerPopup />;
  }

  const { history }: { history: IHistory } = cardsHistory.data;

  return (
    <div>
      <div className="row">
        <div className="col-half">
          <table className="details simple" cellSpacing="1">
            <tbody>
              <TableField {...formFields.card_number}>
                <TextField {...formFields.card_number}></TextField> 
              </TableField>
            </tbody>
          </table>
        </div>
        <div className={`col-half ${styles["history-search"]}`}>
          <table className="details simple" cellSpacing="1">
            <tbody>
              <TableField {...operation_type}>
                <div className="flex">
                  <div className="grow-1">
                    <SelectField
                      {...operation_type}
                    />
                  </div> 
                  <div>
                    <button onClick={submitForm} className="primary button">
                      Szukaj
                    </button>
                  </div>
                </div>
              </TableField>
            </tbody>
          </table>
        </div>
      </div>
      {!history.items.length ? (
        <Alert message="Brak wyników" />
      ) : (
        <div className="fields-group">
          <div className="fields-group-body">
            <table className="list waste-list">
              <thead id="thead-browse">
                <tr>
                  <th className={`text-left`}>Data wpisu</th>
                  <th className={`text-left`}>
                    Nr karty / identyfikator przekazania
                  </th>
                  <th className={`text-left`}>Data wytworzenia</th>
                  <th className={`text-left`}>Data przekazania</th>
                  <th className={`text-left`}>Miejsce wytworzenia</th>
                  <th className={`text-left`}>Rodzaj operacji</th>
                  <th></th>
                </tr>
              </thead>
              <tbody id="tbody-browse">{renderTableItems(history.items)}</tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

const FormWrapper = (props: any) => {
  // {cardsHistory : any, execFetchCardsHistory : (val : ICardSearchForm) => void }
  const [
    cardsHistory,
    execFetchCardsHistory,
  ] = useAsyncAction((values: ICardSearchForm | null) =>
    fetchCardsHistory(values)
  );
  const onSubmit = (values: ICardSearchForm) => {
    execFetchCardsHistory(values);
  };
  const initialValues: ICardSearchForm = {
    operation_type: null,
    card_number: null,
  };
  const formConfig = {
    onSubmit: onSubmit,
    initialValues: initialValues,
  };
  const passedProps = {
    ...props,
    cardsHistory,
    execFetchCardsHistory,
  };
  return (
    <Formik {...formConfig}>
      {(formikProps) => {
       
        return <><WasteList {...passedProps} {...formikProps} /></>;
      }}
    </Formik>
  );
};

function SideEffects(){
  
  const {values} = useFormikContext<ICardSearchForm>();
  useEffect(() => {
    
  
  }, [])
  return null;
}
// function WasteSummary() {
//   return (
//     <div>
//       <WasteList />
//     </div>
//   );
// }

export default FormWrapper;
