const apiVersion = 'v1'

// const hostname = window && window.location && window.location.hostname

const env = process.env.REACT_APP_CUSTOM_ENV || process.env.NODE_ENV

const workshopHosts = {
  development: 'https://sandbox.warsztat.integrator.org.pl',
  staging: 'https://sandbox.warsztat.integrator.org.pl',
  production: 'https://warsztat.integrator.org.pl'
}

const integratorHosts = {
  development: 'https://sandbox.integrator.org.pl',
  staging: 'https://sandbox.integrator.org.pl',
  production: 'https://integrator.org.pl'
}

const systemIntegratorHosts = {
  development: 'http://sandbox.system.integrator.org.pl',
  staging: 'http://sandbox.system.integrator.org.pl',
  production: 'https://system.integrator.org.pl'
}

const frontHosts = {
  development: 'http://localhost:3000',
  staging: 'https://sandbox.warsztat.integrator.org.pl',
  //do ustalenia
  production: 'https://warsztat.integrator.org.pl'
}

const integratorHost = integratorHosts[env]
const systemIntegratorHost = systemIntegratorHosts[env]
const workshopBackHost = workshopHosts[env];
const frontHost = frontHosts[env];

export const ENV = env
export const BACK_BASE_URL = `${integratorHost}`
export const SYSTEM_BASE_URL = `${systemIntegratorHost}`
export const API_URL = `${workshopBackHost}/api`
export const FRONT_BASE_URL = `${frontHost}/waste-records`
export const MAX_FILE_SIZE = 3000000
export const customENV = env;
