import React, { useState , useEffect} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import style from "styles/modules/tabs.module.scss";
import WasteSummary from './waste-summary'
import WasteHistory from './waste-history'


function WasteListT() {
  const [tabIndex, setTabIndex] = useState(0);
  const [summaryYear, setSummaryYear] = useState("2020");

  const tabProps = {
    selectedClassName: style.selected,
    className: style.tab,
  };
  const year2020 = {
    className: `${style.tab} ${summaryYear == "2020" && style.selected}`,
    onClick: () => setSummaryYear("2020"),
  };
  const year2019 = {
    className: `${style.tab} ${summaryYear == "2019" && style.selected}`,
    onClick: () => setSummaryYear("2019"),
  };
  const year2018 = {
    className: `${style.tab} ${summaryYear == "2018" && style.selected}`,
    onClick: () => setSummaryYear("2018"),
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const historyTab = urlParams.get('history');
    if(historyTab){
      setTabIndex(1);
    }
  }, [])
  return (
    <div>
      {" "}
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => setTabIndex(tabIndex)}
      >
        <TabList className={`${style.tablist}`}>
          <Tab {...tabProps}>Magazyn</Tab>
          <Tab {...tabProps}>Historia</Tab>
          {/* <div
            className={`${style.tabs_right} ${tabIndex !== 0 && style.hidden}`}
          >
            <div {...year2020}>2020</div>
            <div {...year2019}>2019</div>
            <div {...year2018}>2018</div>
          </div> */}
        </TabList>
    
        <TabPanel>
          <WasteSummary />
        </TabPanel>
        <TabPanel>
        <WasteHistory />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default WasteListT;
