import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import InputMask from "react-input-mask";
import Modal from "react-modal";
import TableFields, {ErrorMsg} from "components/input/table-fields";
import * as Yup from "yup";
import { FIELD, aupTypeOpts } from "form-config/address-config";
import {addressValidation} from 'validators/address-schema'
import TableField from 'components/input/table-field';
import {address1Fields} from 'form-config/waste-service-config';
import {IAddressState} from 'types/address-types';
import {useAsyncAction} from 'utils/async-action';
import {createAddress} from 'actions/company';
import {zipCodeRegExp} from 'validators/index';
import {checkPostalCode} from 'actions/company';

import _ from 'lodash'
import SpinnerPopup from 'components/common/spinner-modal'

import style from "./waste-address.module.scss";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function WasteAddress(props: any) {
  const {
    modalIsOpen,
    setModalIsOpen,
    selectedWasteId,
    setFieldValue,
    editedWaste,
    values,
    errors,
    submitForm,
    isValid,
    setFieldTouched,
    setIsSubmitting,
    wasteCardMode,
    execFetchAddresses,
    setSelectedAddress
  } = props;
  const addressState : IAddressState = props.addressState;

  const [streetDisabled, setStreetDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cityOpts, setCityOpts] = useState<Array<any>>([]);
  const [streetOpts, setStreetOpts] = useState<Array<any>>([]);
  const [postalOfficeOpts, setPostalOfficeOpts] = useState<Array<any>>([]);
  const [postalCodeData, execCheckPostalCode] = useAsyncAction(checkPostalCode);


  /* functions */


  const preloadAddress = (event : {target : {value : string}}) => {
    const text = event.target.value;
    setFieldValue(FIELD.ZIP_CODE, event.target.value);
    setFieldTouched(FIELD.ZIP_CODE, true, false);
    if (zipCodeRegExp.test(text)) {
      setTimeout(async function () {
        setIsLoading(true);
        setStreetOpts([]);
        setCityOpts([]);
        const res = await execCheckPostalCode(text);
        if (res && res.data.length) {
          res.data.map((item: any, index: number) => {
            setStreetOpts((oldArray: Array<any>) => [
              ...oldArray,
              { value: item.street, label: item.street },
            ]);

            setCityOpts((oldArray : Array<any>) => [
              ...oldArray,
              { value: item.city, label: item.city },
            ]);

            setPostalOfficeOpts((oldArray: Array<any>) => [
              ...oldArray,
              { value: item.post_office, label: item.post_office },
            ]);
          });

          setCityOpts((oldArray) => _.uniqWith(oldArray, _.isEqual));
          setPostalOfficeOpts((oldArray) => _.uniqWith(oldArray, _.isEqual));

          setFieldValue("address.voivodeship", res.data[0].voivodeship);
        }
        // else{
        //   errorMessage('Brak danych adresowych dla podanego kodu')
        // }
        setIsLoading(false);
      }, 500);
    }
  };
  function closeModal() {
    setModalIsOpen(false);
  }

  /**  side effects  */
  useEffect(() => {
    if(addressState.data){
      setSelectedAddress(addressState.data);
    }
  }, [addressState.data]);

  useEffect(() => {
    if (values.address.aup_type == "-") {
      setStreetDisabled(true);
      setFieldValue(FIELD.STREET, "");
    } else {
      setStreetDisabled(false);
    }
  }, [values.address.aup_type]);

  useEffect(() => {
    if(setIsSubmitting){
      setIsSubmitting(addressState.loading)
    }
    
  }, [addressState.loading]);

  useEffect(() => {
    if(addressState.response){
      setModalIsOpen(false);
        
        execFetchAddresses();
      
      
    }
  }, [addressState.response]);

  /** form fields */

  const formFields1 = [
    {
      name: FIELD.ZIP_CODE,
      type: "text",
      label: "Kod pocztowy",
      required: true,
      // onChange: preloadAddress,
      maxlength: 6,
    },
    {
      name: FIELD.VOIVODESHIP,
      type: "text",
      label: "Województwo",
      required: true,
    },
    {
      name: FIELD.CITY,
      type: "text",
      label: "Miejscowość",
      required: true,
    },
    {
      name: FIELD.POST_OFFICE,
      type: "text",
      label: "Poczta",
      required: true,
    }
  ];

  const formFields2 = [
    {
      name: FIELD.AUP_TYPE,
      type: "select",
      label: "Typ adresu",
      options: aupTypeOpts,
      required: true,
    },
    {
      name: FIELD.STREET,
      type: "text",
      label: "Ulica",
      required: !streetDisabled,
      disabled: streetDisabled,
    },
    {
      name: FIELD.BUILDING_NR,
      type: "text",
      label: "Numer domu",
      required: true,
    },
    { name: FIELD.LOCUM_NR, type: "text", label: "Numer lokalu" },
  ]; 


  const addressFieldsData = address1Fields({
    streetDisabled,
    streetOpts,
    cityOpts,
    postalOfficeOpts,
    preloadAddress,
    setFieldValue,
    values,
  });


  const zipCodeField = {
    name: FIELD.ZIP_CODE,
    type: "text",
    label: "Kod pocztowy",
    required: true,
    onChange: preloadAddress,
  };



  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {isLoading && <SpinnerPopup />}
       
      <div className={style.modal}>
        <h2>Nowy adres</h2>
        <p>Wprowadź adres miejsca gdzie generowane będą odpady</p>
        {/* <div className="row"> */}
          {/* <div className="half-col"> */}
            <table className="details" cellSpacing="1">
              <tbody>
              <TableField {...zipCodeField}>
                <div>
                  <Field {...zipCodeField}>
                    {({ field, form }: any) => {
                      return <>
                        <InputMask
                          className="text"
                          {...zipCodeField}
                          
                          mask="99-999"
                        />
                        
                      </>
                    }}
                      
                  </Field>
                  <ErrorMessage name={zipCodeField.name} render={ErrorMsg} />
                </div>
              </TableField>
                <TableFields
                  //   setFieldValue={setFieldValue}
                  fields={addressFieldsData}
                  disabled={false}

                  //   disabled={isLoading}
                />
              </tbody>
            </table>
          {/* </div> */}
          {/* <div className="half-col">
            <table className="details" cellSpacing="1">
              <tbody>
                <TableFields
                  //   setFieldValue={setFieldValue}
                  fields={formFields2}
                />
              </tbody>
            </table>
          </div> */}
        {/* </div> */}
        <div className="buttons">
          <button
            type="button"
            className="button button-link"
            onClick={submitForm}
            disabled={!isValid}
          >
            Zapisz
          </button>
        </div>
      </div>
    </Modal>
  );
}

const WasteAddressForm = (props: any) => {
 const [addressState, execCreateAddress] : readonly [IAddressState, any] = useAsyncAction(createAddress);
const [test, setTest] = useState(false);
 
const onSubmit = (formValues: any) => {
    execCreateAddress(formValues);
    
  };
  const initialValues = { address : {}};
  const validationSchema = Yup.object().shape({
    address: Yup.object().shape(addressValidation),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      isInitialValid={false}
    >
      {(formik) => {
        const formikProps = {
          ...formik,
          addressState,
        };
        return <WasteAddress {...props} {...formikProps} />;
      }}
    </Formik>
  );
};

export default WasteAddressForm;
