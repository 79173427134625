import React, { useState, useEffect, Suspense, lazy } from "react";
import { Formik, Form } from "formik";
import PageHeader from "components/common/page-header";
import style from "./waste-card-form.module.scss";
import { stepsValidationSchema } from "form-config/waste-card-config";
import { useAsyncAction } from "utils/async-action";
import { fetchAddresses } from "actions/company";
import { fetchCodes, createWasteCard } from "actions/waste";
import { IWasteCardCreateBody } from "types/waste-card-types";
import { IWasteAddress, IAddressesState } from "types/address-types";
import { IWasteCardFormValues } from "types/waste-card-types";
import { ICodesState } from "types/waste-types";
import { IActionState } from "types/async-types";
import SpinnerPopup from "components/common/spinner-modal";
import Step1 from "./step-1";
import { useHistory } from "react-router-dom";
import { PATH } from "../../../path";
import { withAuthSync } from "utils/auth";
import _ from "lodash";

const Step3 = lazy(() => import("./step-3"));
// const renderFields = (items) => {
//   return items.map((item, index) => {
//     return (
//       <tr key={index} className="p5-row">
//         <td className="caption">{item.label}</td>
//         <td className="value">
//           <Field className="text" type={item.type} name={item.name} />
//           <ErrorMessage name={item.name} component="div" />
//         </td>
//       </tr>
//     );
//   });
// };

const WasteCardForm = () => {

  const [wasteCardMode, setWasteCardMode] = useState(false);
  const [stepNo, setStepNo] = useState<number>(1);
  const history = useHistory();
  const [wasteRecords, setWasteRecords] = useState({});
  const [header, setHeader] = useState<string | null>(null);
  const [cardNr, setCardNr] = useState<string | null>(null);
  const [cardDate, setCardDate] = useState<string | null>(null);
  const [addressesState, execFetchAddresses]: readonly [
    IAddressesState,
    any
  ] = useAsyncAction(fetchAddresses);
  const [codesState, execFetchCodes]: readonly [
    ICodesState,
    any
  ] = useAsyncAction(fetchCodes);
  const [wasteCardState, execCreateWasteCard]: readonly [
    IActionState,
    any
  ] = useAsyncAction(createWasteCard);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState<IWasteAddress | null>(
    null
  );

  const schema: {
    [key: number]: any;
  } = stepsValidationSchema(wasteCardMode);

  let street, locum_number, building_number, city, postal_code;
  if (selectedAddress != null) {
    ({
      street,
      locum_number,
      building_number,
      city,
      postal_code,
    } = selectedAddress);
  }

  const initialValues : IWasteCardFormValues = { is_card: wasteCardMode, codes: {}, no_card_number: [] };
  
  const onSubmit = (values: IWasteCardFormValues) => {

    let data : IWasteCardCreateBody = {
      waste_card:   _.clone(values),
      // no_card_number :  values.no_card_number && values.no_card_number[0] == 'true'
    };
    data.waste_card.no_card_number = values.no_card_number && values.no_card_number[0] == 'true';
    let codesArray = [];
    for (const [key, item] of Object.entries(values.codes)) { 
      const typeditem: any = item;
      if (item) {
        codesArray.push({
          normalized_code: key,
          amount: typeditem.amount,
          unit: typeditem.unit,
        });
      }
    }
    data.waste_card.codes = codesArray;
    data.waste_card.card_type = wasteCardMode ? "import_export" : "import";
    execCreateWasteCard(data);
  };

  useEffect(() => {
    if (wasteCardState.data) {
      history.push(`${PATH.WASTE}?history=true`);
    }
  }, [wasteCardState.data]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let isCard: boolean | string | null = urlParams.get("wastecard");
    isCard = isCard === "true";
    setWasteCardMode(isCard);

    execFetchAddresses();
    execFetchCodes();
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", onBackClick);
    return () => {
      window.removeEventListener("popstate", onBackClick);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    let withWasteCard: string | boolean | null = urlParams.get("wastecard");
    let stepNoParam = urlParams.get("step");
    let sid = urlParams.get("sid");
    withWasteCard = withWasteCard === "true";
    setWasteCardMode(withWasteCard);
    if (stepNoParam !== "1") {
      history.push({
        pathname: "/waste-card-create",
        search: `?step=1&wastecard=${withWasteCard ? "true" : "false"}&`,
        // search: `?step=1&wastecard=${withWasteCard ? "true" : "false"}&${sid !== (null || undefined) ? `sid=${sid}`: ''}`,
      });
    }

    setHeader(
      withWasteCard ? "Dodaj odpad z kartą KPO" : "Dodaj odpad bez karty KPO"
    );
  }, []);

  const setStep = (
    stepNo: number,
    validateForm: (values?: any) => Promise<any>
  ) => {
    setStepNo(stepNo);

    const urlParams = new URLSearchParams(window.location.search);
    let withWasteCard = urlParams.get("wastecard");
    history.push({
      pathname: "/waste-card-create",
      search: `?step=${stepNo}&wastecard=${withWasteCard}`,
    });
    validateForm();
  };

  const onBackClick = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const stepNo = urlParams.get("step");

    if (window.location.pathname == PATH.WASTE_CARD_CREATE) {
      if (!stepNo) {
        history.back();
      } else {
        setStepNo(parseInt(stepNo));
      }
    }
  };

  const renderButtons = (
    isValid: boolean,
    validateForm: (values?: any) => Promise<any>,
    submitForm: (() => Promise<void>) & (() => Promise<any>)
  ) => {
    const prevStep = stepNo - 1;
    const nextStep = stepNo + 1;

    return (
      <div className="buttons">
        {stepNo > 1 && (
          <a
            className="button inverted-button"
            onClick={() => setStep(prevStep, validateForm)}
          >
            Wstecz
          </a>
        )}
        {stepNo == 2 ? (
          <button
            disabled={!isValid}
            type="submit"
            onClick={submitForm}
            className="button button-link"
          >
            Zapisz odpady
          </button>
        ) : (
          <button
            type="button"
            className="button button-link"
            disabled={!isValid}
            onClick={() => {
              setStep(nextStep, validateForm);
            }}
          >
            Kontynuuj
          </button>
        )}
      </div>
    );
  };

  // const schema = stepsValidationSchema({isCard});

  return (
    <div>
      {/* <WasteAddress /> */}
      {/* {(stepNo == 1 || !wasteCardMode) && <PageHeader title={header} />} */}
      {(addressesState.loading || wasteCardState.loading || isLoading) && (
        <SpinnerPopup message="zapisywanie danych" />
      )}

      <div>
        {stepNo > 1 && wasteCardMode ? (
          <PageHeader noMargin={true} title={`${header}${cardNr ? `: ${cardNr}` : ""}`} />
        ) : (
          <PageHeader title={header} />
        )}
        {stepNo > 1 &&
           <div className={`${style.extended_header} md-t`}>
           <div className={style.date_info}>
             <span className="grey">Data przekazania odpadów: </span>
             <span className="dark">{cardDate}</span>
           </div>
           <span className="grey">Miejsce generowania odpadów: </span>
           <span className="dark">{`${street} ${building_number}${
             locum_number ? `/${locum_number}` : ""
           } ${postal_code} ${city}`}</span>
         </div>
       
        }
        </div>
       

      <Formik
        initialValues={initialValues}
        isInitialValid={false}
        enableReinitialize={true}
        validationSchema={schema[stepNo]}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          submitForm,
          values,
          setFieldValue,
          errors,
          isValid,
          validateForm,
          setFieldTouched,
        }) => {
          const stepProps = {
            execFetchAddresses,
            setStepNo: setStepNo,
            // renderFields: renderFields,
            wasteRecords,
            setWasteRecords,
            setFieldValue,
            values,
            validateForm,
            setFieldTouched,
            setStep,
            wasteCardMode,
            setHeader,
            setCardDate,
            setCardNr,
            addressesState,
            setSelectedAddress,
            codesState,
            setIsLoading,
            isLoading,
            selectedAddress,
            errors
          };
          const lastStepProps = {
            ...stepProps,
            submitForm: submitForm,
            isSubmitting: isSubmitting,
          };

          
          
          
          return (
            <div>
              <Form className={`${style.waste_card_form}`}>
                {stepNo === 1 && <Step1 {...stepProps} />}
                {stepNo === 2 && (
                  <Suspense fallback={<SpinnerPopup />}>
                    <Step3 {...lastStepProps} />
                  </Suspense>
                )}
                {/* {stepNo === 3 && <Step3 {...stepProps} />}
              {stepNo === 4 && <Step4 {...lastStepProps} />} */}
              </Form>
              {renderButtons(isValid, validateForm, submitForm)}
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default withAuthSync(WasteCardForm);
