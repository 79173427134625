import * as Yup from 'yup'

export const email = (value: string) => {
    let errorMessage;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      errorMessage = "Niepoprawny adres email";
    }
    return errorMessage;
  };
  
  // export const customRequired = (value: string) => {
  //   let errorMessage;
  //   if (value == "" || !value) {
  //     errorMessage = "Pole jest wymagane";
  //   }
  //   return errorMessage;
  // };

  export const customRequired = (value: any) =>
  value ? undefined : "To pole jest wymagane";

  export const customCheckRequired = (values: any, value : string) =>  {
    
    
    return (values && values.length > 0) ? undefined : "To pole jest wymagane";
  }
  
  
  // export const validation = (value, validations) => {
  //   for (let validation of validations) {
  //     const result = validation(value);
  //     if (result) {
  //       return result;
  //     }
  //   }
  //   return null;
  // };


  export const required = Yup.string().required("pole jest wymagane");
  export const dateRequired = Yup.date().required("pole jest wymagane");
  export const minLength = (nr: number) => Yup.string().min(nr, `Minimalna liczba znaków - ${nr}`)
const shortPhone = /(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/;
const matRegex = /^(\+48)?\d{9}$/;
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  export const phoneNumber =  Yup.string().matches(matRegex, 'Numer telefonu jest niepoprawny (wymagana liczba znaków: 9, dopuszczalne cyfry 0-9 i "+")')

  export const zipCodeRegExp = /\d{2}-\d{3}/
  export const zipCode =  Yup.string().matches(zipCodeRegExp, 'Kod pocztowy jest niepoprawny')

  export const nipRegExp = /[0-9]{10}/
  export const nipNumber =  Yup.string().matches(nipRegExp, 'NIP jest nieprawidłowy (jedynie cyfry, wymagana ilość znaków - 10)')


  export const regonRegExp = /[0-9]{9}/
  export const regonNumber =  Yup.string().matches(regonRegExp, 'REGON jest nieprawidłowy (jedynie cyfry, wymagana ilość znaków - 9)')