import React, { useState, useEffect } from "react";
import PageHeader from "components/common/page-header";
import {useHistory} from 'react-router-dom';
import { Formik, FormikProps } from "formik";
import { showWasteCard, editWasteCard, editTransferCard } from "actions/waste";
import {IWasteCard, IWasteCardFormValues} from 'types/waste-card-types'
import {ICode} from 'types/waste-types'
import {formatDate} from 'utils/date';
import { fetchAddresses } from "actions/company";
import { useAsyncAction } from "utils/async-action";
import WasteAddress from '../waste-create-form/waste-address/waste-address'
import TableField from "components/input/table-field";
import TableTextField from "components/input/table-text-field";
import CheckFormField from 'components/input/check-form-field';
import TextField from "components/input/text-field";
import SelectField from "components/input/select-field";
import { useParams } from "react-router-dom";
import {required, dateRequired} from 'validators/index';
import SpinnerPopup from "components/common/spinner-modal";
import _ from "lodash";
import * as Yup from 'yup'
import { PATH } from "../../../path";
import { FIELD_NAMES } from "form-config/waste-card-config";
import style from "../waste-create-form/waste-card-form.module.scss";

interface  IProps  {
  [x: string] : any,
  wasteCard : IWasteCard,

}
function WasteCardEdit(props: IProps & FormikProps<IWasteCardFormValues>) {
  const {
    values,
    setFieldValue,
    execShowWasteCard,
    wasteCardState,
    submitForm,
    id,
    editWasteCardState,
    editTransferCardState,
    isValid,
    errors,
    setFieldTouched
    
  } = props;
  
  
  const {wasteCard}: {wasteCard: IWasteCard} = props; 


  const history = useHistory();

  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [noCard, setNoCard] = useState(false);
  const [addressOpts, setAddressOpts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressesState, execFetchAddresses] = useAsyncAction(fetchAddresses);
  const [wasteCardMode, setWasteCardMode] = useState(false);

  


  const unitOptions = [
    { value: "kg", label: "kg", default: true },
    { value: "mg", label: "mg" },
  ];

  // const onAmountChange = (e: any, code: string) => {
  //   const val = parseInt(e.target.value);
  //   if (!val) {
  //     setFieldValue(`items[${code}]`, null);
  //   } else {
  //     setFieldValue(`items[${code}]`, {
  //       amount: val,
  //       unit:
  //         values.items[code] && values.items[code].unit
  //           ? values.items[code].unit
  //           : "kg",
  //     });
  //   }
  // };

  const onUnitChange = (e: {target : {value : string}}, code: string) => {
    const val =e.target.value;
    
    // if(!values.codes[code] || !values.codes[code].amount){
    //   return;
    // }
    let amount = values.codes[code].amount;
    if(amount != null){
      if(val == 'kg'){
        amount = amount * 1000;
      }else if( val == 'mg'){
        amount = amount / 1000;
      }
    }
  

    setFieldValue(`codes[${code}]`, {
      ...values.codes[code],
        amount: amount,
        unit: val
      });

      setFieldTouched(`codes[${code}]`, true, true);
  };

  const renderCodes = () => {
    if(!wasteCard){
      return <tr></tr>
    }

    function validateCode(amount: number, code : string) {
    
      let unit: string | undefined = "";
      if (values.codes[code]) {
        unit = values.codes[code].unit ? values.codes[code].unit : 'kg' ;
      }
      debugger;
      let error;
      
      if (wasteCard.card_type != "import" &&  (amount === 0 || (unit === 'kg' && amount < 1 ) || (unit === 'mg' && amount < 0.001 ))) {
        error = 'Wartość nie może być równa 0';
      }
      return error;
    }

    const codes : any = errors.codes;
    
    return wasteCard.items.map((item: ICode, index: number) => {
      return (
        <tr id="record-2" className="odd record-2-row first">
          <td className="code">
            <div id="code">{item.code}</div>
          </td>{" "}
          <td className="_name">
            <div id="_name">
              {item.name}
              <div className="info">{item.info}</div>
            </div>
          </td>{" "}
          <td className="_check">
            <div className={`${style.waste_inputs} row`}>
              <div className="half-col">
                <TextField
                  name={`codes['${item.normalized_code}'].amount`}
                  type="number"
                  min="0"
                  validate={(amount: number) => validateCode(amount, item.normalized_code)}
                  disableErrorMessage={true}
                  // onChange={(e: any) => onAmountChange(e, item.normalized_code)}
                />
                 {(errors.codes &&  ( item.normalized_code && codes[item.normalized_code] ) &&
                      <label className="validation_error">{codes[item.normalized_code].amount}</label>
                  ) }
              </div>
              <div className="half-col">
                <SelectField
                  name={`codes['${item.normalized_code}'].unit`}
                  type="select"
                  options={unitOptions}
                  disableDefault={true}
                  initialValue="kg"
                  onChange={(e: {target : {value : string}}) => onUnitChange(e, item.normalized_code)}
                />
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  /** side effects */
  useEffect(() => {
    const noCard : Array<string>= values.no_card_number;
    // setTimeout(() => validateForm());
    if(noCard.length > 0){
          setNoCard(true);
          setFieldValue(FIELD_NAMES.CARD_NR, "" );
    }else{
      setNoCard(false);
    }
    
    // setTimeout(() => validateForm());
  }, [values.no_card_number]);

  useEffect(() => {
    execShowWasteCard(id);
  }, []);

  useEffect(() => {
    execFetchAddresses();
  }, []);

  useEffect(() => {
      if(editWasteCardState.data || editTransferCardState.data ){
        history.push(`${PATH.WASTE}`);
      }
  }, [editWasteCardState.data, editTransferCardState.data ]);

  useEffect(() => {
    const addresses = addressesState.data;
    if (addresses) {
      if (!addresses.length) {
        setAddressOpts([]);
      } else {
        const opts = addresses.map((item: any, index: number) => {
          return {
            label: `${item.street} ${item.building_number}${item.locum_number ? `/${item.locum_number}` : ''} ${item.postal_code} ${item.city}`,
            value: item.id,
          };
        });
        setAddressOpts(opts);
      }
    }
  }, [addressesState.data]);

  // *** form fields *************************************** //
  const formFields = {
    [FIELD_NAMES.CARD_NR]: {
      name: FIELD_NAMES.CARD_NR,
      type: "text",
      label: "Numer karty",
      required: !noCard,
      disabled : noCard || (wasteCard && wasteCard.card_type != 'import_export')
    },

    no_card_number: {
      id: `no_card_number`,
      name: FIELD_NAMES.NO_CARD,
      cbxLabel: "Nie posiadam numeru karty",
      value : 'true'
    },

    manufacture_date : {
      name: (wasteCard && wasteCard.card_type == 'export') ? 'transfer_date' : FIELD_NAMES.DATE,
      type: "date",
      label: (wasteCard && (wasteCard.card_type == 'export' || wasteCard.card_type == 'import_export') ) ? 'Data przekazania odpadu' : 'Data wytworzenia odpadu',
      required: true,
    },
    [FIELD_NAMES.WASTE_ADDRESS]: {
      name: FIELD_NAMES.WASTE_ADDRESS,
      type: "text",
      label: "Miejsce generowania odpadu",
      required: true,
      options: addressOpts,
    },
  };

  // adding_disabled : true,
  //   setFieldValue,
  //   onChange : onAddressChange,
  //   values,
  //   wasteCardMode : true,
  //   addressesState,
  //   execFetchAddresses,
  //   addressField : fieldData[field.WASTE_ADDRESS]

  const addressModalProps = {
    adding_disabled: true,
    input_disabled: true,
    setFieldValue,
    values,
    setIsSubmitting,
    setSelectedAddress,
    addressField : formFields[FIELD_NAMES.WASTE_ADDRESS],
    addressesState,
  };

  if (
    editWasteCardState.loading ||
    editTransferCardState.loading )
    {
      return <SpinnerPopup message="Zapisywanie danych" />;
    }
  if(wasteCardState.loading ||
    addressesState.loading ){
      return <SpinnerPopup  />;
    }

    if(!wasteCard){
      return <div></div>
    }

    
  

  return (
    <div>
      <PageHeader noMargin={true} title="Edycja odpadu" />
      {/* {(isSubmitting || isLoading) && <SpinnerPopup/>} */}
      {/* {!values.is_card &&
        <AddressModal {...addressModalProps} />
      } */}
      
      <table className="details mb-30" cellSpacing="1">
        <tbody>
          {/* {wasteCardMode && <TableTextField {...formFields[FIELD_NAMES.CARD_NR]} />} */}
          {/* {wasteCard.card_type === 'import_export' && */}
          <TableField {...formFields[FIELD_NAMES.CARD_NR]}>
            <div>
              <TextField {...formFields[FIELD_NAMES.CARD_NR]} />
            
            {wasteCard.card_type === 'import_export' &&
            <div className="mt-10">
              <CheckFormField  {...formFields.no_card_number}/>  
              </div>
            }
            
          
          </div>
          </TableField>
         {/* } */}
          <TableTextField {...formFields.manufacture_date} />
          <WasteAddress {...addressModalProps} />
         
          
        </tbody>
      </table>

      <h2>Kody odpadów</h2>
      <table className="list" id="table-w6">
        <thead id="thead-w6">
          <tr>
            <th className="code">Nr i kod odpadu</th>
            <th className="_name">Rodzaj odpadu</th>
            <th className="text-left">Masa odpadu</th>
          </tr>
        </thead>
        <tbody id="tbody-w6">{renderCodes()}</tbody>
      </table>
      <div className="buttons">
        <button
          type="button"
          onClick={submitForm}
          disabled={!isValid}
          className="button button-link"
        >
          Zapisz
        </button>
      </div>
    </div>
  );
}

const FormWrapper = (props: IProps) => {
  const [wasteCardState, execShowWasteCard] = useAsyncAction(showWasteCard);
  const [editWasteCardState, execEditWasteCard] = useAsyncAction(editWasteCard);
  const [editTransferCardState, execEditTransferCard] = useAsyncAction(
    editTransferCard
  );
  const [initialValues, setInitialValues] = useState<IWasteCardFormValues>({codes: {}, no_card_number : [] });

  const { id }: { id: string } = useParams();
  const wasteCard : IWasteCard = wasteCardState.data;

  useEffect(() => {
    if (wasteCardState.data) {
      const {
        is_card,
        card_number,
        manufacture_date,
        transfer_date,
        address_id,
        card_type
      } = wasteCard;
      
      // let dateStr : string | null = null;
      // if(transfer_date){
      //   const date = new Date(transfer_date);
      //   const month = (date.getMonth() + 1);
      //   let monthStr = "";
      //   if(month < 10){
      //       monthStr = '0' + month
      //   }else{
      //     monthStr = month.toString();
      //   }
      //    dateStr = date.getFullYear() + "-" + monthStr + "-" + date.getDate()
        
      // }
      
      
      const initialValues : IWasteCardFormValues  = {
        is_card, 
        card_number,
        manufacture_date : manufacture_date ? manufacture_date : '',
        transfer_date : formatDate(transfer_date),
        address_id, 
        card_type,
        codes: _.keyBy(wasteCard.items, "normalized_code"),
        no_card_number : (card_number == null || card_number == "") ? ["true"] : []
      };
      setInitialValues(initialValues);
    }
    
  }, [wasteCardState.data]);

  const onSubmit = (values: IWasteCardFormValues) => {
    let data = {};
      
      const { manufacture_date, card_number, address_id, no_card_number } = values;
      data = {
        manufacture_date,
        address_id,
        card_number,
        codes: values.codes,
        no_card_number : no_card_number[0] == 'true'
      };
      execEditWasteCard(id, data);
    // }
    
  };

   const schema =  Yup.object().shape({
      no_card_number : Yup.string(),
      card_number: Yup.string().when(FIELD_NAMES.IS_CARD, {
        is: false,
        then: Yup.string().notRequired(),
        otherwise: Yup.string().when(FIELD_NAMES.NO_CARD, {
          is: "true",
          then: Yup.string().notRequired(),
          otherwise: Yup.string().concat(required),
        })
      }),
      address_id: Yup.string().concat(required),
      // manufacture_date: Yup.date().concat(dateRequired).test(`manufacture_date`, `Dopuszczalna jest tylko data między 01.01.2019 a dniem bieżącym`, function (
      //   value
      // ) {
        
      //   if(value > new Date() || value < new Date('01-01-2019')){
      //     return false;
      //   }else{
      //     return true;
      //   }
      // })
    })


  const formConfig = {
    onSubmit: onSubmit,
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: schema
  };
  const childProps = {
    ...props,
    wasteCardState,
    execShowWasteCard,
    id,
    editWasteCardState,
    editTransferCardState,
    wasteCard
  };

  return (
    <Formik {...formConfig}>
      {(formikProps : FormikProps<IWasteCardFormValues>) => {
          
        return <WasteCardEdit {...childProps} {...formikProps} />;
      }}
    </Formik>
  );
};

export default FormWrapper;
