import axios from "axios";
import { API_URL } from "config";
import { requestHeaders } from "config/headers";

export default axios.create({
    baseURL: `${API_URL}`,
    headers: {
      Authorization: requestHeaders().Authorization,
      Accept: "application/json",
      "Content-Type" : 'application/json',
    },
  });
  