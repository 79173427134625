import axios from "axios";
import { API_URL } from "config";
import {requestHeaders} from 'config/headers'

export const loginByToken = (sid, redirectUrl = null) => async (dispatch) => {
  // alert(`SID: ${sid}`)
  const data = { token: sid };
  const options = {
    url: `${API_URL}/users/login_by_token`,
    method: "POST",
    data: data,
  };

  let res = await axios(options);
  localStorage.setItem('token', res.data.api_token)
  
   dispatch({
    type: "LOGIN_BY_TOKEN",
    payload: { user: res.data },
  });
  return res;
};

export const checkAuth = () => async (dispatch) => {
  const options = {
    url: `${API_URL}/users/profile`,
    method: "GET",
    headers: {
      Authorization: requestHeaders().Authorization
    }
  };

  let res = await axios(options);
  
   dispatch({
    type: "AUTH_USER",
    payload: { user: res.data },
  });
  return res;
};
