import React from "react";
import { Field, ErrorMessage } from "formik";
import inputStyle from "./table-fields.module.scss";
const ErrorMsg = (msg: string) => <span className="input-error">{msg} </span>;
const renderOpts = (opts: Array<{ label: string; value: string; default?: boolean }>, disableDefault = false) => {
  // if(!opts){
  //   return null;
  // }
  const options = opts.map((opt: any, index: number) => (
    <option key={index} data-testid={`option_${index}`} value={opt.value} selected={opt.default}>
      {opt.label}
    </option>
  ));
  if (!disableDefault) {
    options.unshift(
      <option
      key="opt_placeholder"
      data-testid={`option_placeholder`}
        value=""
        className={inputStyle.select_placeholder}
        disabled
        selected
      >
        -- Wybierz --
      </option>
    );
  }
  return options;
};

interface IProps {
  disabled? : boolean
  name: string
  options: Array<{label: string, value : string}>
  disableDefault? : boolean,
  onChange?: (a: any) => void
}

function TableSelectField(props: IProps) {
  const { name, options, disableDefault, onChange } = props;
  let mergedProps = {
    ...props,
  };
  if (onChange) {
    mergedProps.onChange = (e) => onChange(e.target.value);
  }

  return (
    <>
      <Field data-testid="table-select-field" as="select" {...mergedProps} placeholder="--wybierz--">
        {renderOpts(options, disableDefault)}
      </Field>
      <ErrorMessage name={name} render={ErrorMsg} />
    </>
  );
}

export default TableSelectField;
