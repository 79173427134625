import React from "react";
import PageHeader from 'components/common/page-header';
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import style from './alert.module.scss';

export function ConfirmAlert(action: () => void) {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className={style['confirm-alert']}>
          <div className="mb-20">
          <h2>Potwierdzenie operacji</h2>
          <p>Jesteś pewien że chcesz usunąć element ?</p>
          </div>
          <button className="button primary" onClick={onClose}>Nie</button>
          <button className="button inverted-button"
            onClick={() => {
              action();
              onClose();
            }}
          >
            Tak
          </button>
        </div>
      );
    }
  });
}
