import React, { useEffect } from "react";
import { useReduxAction, useAsyncAction } from "utils/async-action";

import SpinnerPopup from "components/common/spinner-modal";
import {FIELD} from 'form-config/waste-service-config'
import { useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import WasteRegisterInfo from "./waste-register-info";
import style from "./register-form.module.scss";
import _ from "lodash";

const RegistertFormStep2 = (props: any) => {
  //props & hook props
  let history = useHistory();
  const async = useSelector((state: RootStateOrAny) => state.async);
  
  const {  validateForm } = props;
  const {
    formButtonsProps,
    formData
  } = props;
  

  useEffect(() => {
    setTimeout(() => validateForm());
  }, []);

  if (async.isLoading) {
    return <SpinnerPopup />;
  }
  return (
    <div>
      <h1 className={`mb-30 ${style.header}`}>Warsztaty samochodowe – administracja odpadów</h1>
      <WasteRegisterInfo formData={formData} formButtonsProps={formButtonsProps} />
    </div>
  );
};

export default RegistertFormStep2;
