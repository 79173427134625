import axios, {AxiosRequestConfig} from "axios";
import { API_URL } from "config";
import {requestHeaders} from 'config/headers'
import _ from 'lodash'

//**** regular API calls */
export const signUp = async (values : any, redirectUrl = null) => {
  let data = {
    registration: {
      client: _.clone(values.client),
      address: _.clone(values.address),
      agreements : values.agreements.join("|"),
      registration_type : values.registration_type
    },
  };
  
  data.registration.client.no_bdo = values.client.no_bdo[0] == 'true';
  data.registration.client.force_tax_number = values.client.force_tax_number[0] == 'true';
  data.registration.client.force_regon_number = values.client.force_regon_number[0] == 'true';

  const options: AxiosRequestConfig = {
    url: `${API_URL}/register_client`,
    method: "POST",
    data: data,
    headers: requestHeaders()
  };
  let res = await axios(options);
  return res;
};

export const openMenuLink = async (queryString : string) => {
  const options: AxiosRequestConfig = {
    url: `${API_URL}/get_system_link${queryString}`,
    method: "get",
    headers: {
     Authorization :  requestHeaders().Authorization,
     ['Access-Control-Allow-Origin'] : '*'
    }
  };
  
  let res = await axios(options);
  return res;
};

