import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PATH } from "../path";
import SpinnerPopup from "components/common/spinner-modal";
import { loginByToken, checkAuth } from "redux/actions/user";
import { useReduxAction } from "utils/async-action";
import { SYSTEM_BASE_URL } from "config";

export const loginRedirect = () => {
  window.location.href = SYSTEM_BASE_URL;
};

export const withAuthSync = (WrappedComponent) => {
 
  const Wrapper = (props) => {
    const [userState, execLoginByToken] = useReduxAction(
      (sid) => loginByToken(sid),
      [],
      "user"
    );
    const [checkAuthState, execCheckAuth] = useReduxAction(
      () => checkAuth(),
      [],
      "user"
    );
    const [authDone, setAuthDone] = useState(false);
    const history = useHistory();
    const user = useSelector((state) => state.user);

    const auth = () => {
      const urlParams = new URLSearchParams(window.location.search);
      let SID = urlParams.get("sid");
      if (SID) {
        execLoginByToken(SID);
      } else {
        const savedToken = localStorage.getItem("token");
        if (savedToken) {
          execCheckAuth();
        } else {
          history.push(PATH.WASTE_REGISTER);
          window.location.reload();
        }
      }

      // const savedToken = localStorage.getItem('token');
      //   
      //   if(!savedToken){
      //     const urlParams = new URLSearchParams(window.location.search);
      //     let SID = urlParams.get("sid");
      //     if(!SID){
      //       history.push(PATH.WASTE_REGISTER)
      //       // window.location.reload();
      //     }else{
      //       execLoginByToken(SID);

      //       // setAuthDone(true);
      //     }

      //   }else {
      //     setAuthDone(true);
      //   }
    };

    useEffect(() => {
      if (checkAuthState.error === 401) {
        
        loginRedirect();
        // window.location.reload();
      }
    }, [checkAuthState]);

    useEffect(() => {
      if (userState.error === 401) {
        
        loginRedirect();
        // window.location.reload();
      }
    }, [userState]);

    useEffect(() => {
      if (user.api_token) {
        
        // setAuthDone(true); /* bring it back in future (once complete user data will be returned from loginByToken() */
        execCheckAuth();  /* for delete in future (once complete user data will be returned from loginByToken() */
      }
    }, [user.api_token]);

    useEffect(() => {
      if (user.token) {
        
        setAuthDone(true);
      }
    }, [user.token]);

    useEffect(() => {
      auth();
    }, []);


    if (userState.loading || userState.error === 401 || !authDone) {
      return <SpinnerPopup />;
    }
    return <WrappedComponent />;
  };
  return Wrapper;
};
