import React, { useState, useEffect } from "react";
import TableTextField from "components/input/table-text-field";
import TableSelectField from "components/input/table-select-field";
import TableField from "components/input/table-field";
import TextField from "components/input/text-field";
import {IAddressesState} from 'types/address-types';
import {FIELD_NAMES} from 'form-config/waste-card-config';
import {IWasteAddress} from 'types/address-types'
import WasteAddress from './waste-address/waste-address'
import AddressModal from "./waste-address/waste-address-modal";
import CheckFormField from 'components/input/check-form-field'
import SpinnerPopup from "components/common/spinner-modal";

import _ from 'lodash';
import { ErrorMessage } from "formik";
import TableFields from "components/input/table-fields";

import style from "./waste-card-form.module.scss";

function Step1(props: any) {

  // *** props & hook variables *************************************** //
  const {
    setFieldValue,
    values,
    wasteCardMode,
    setCardNr,
    setCardDate,
    setSelectedAddress,
    validateForm,
    isLoading,
    execFetchAddresses,
    setFieldTouched,
  } = props;
  const addressesState : IAddressesState = props.addressesState;
  const selectedAddress : IWasteAddress = props.selectedAddress;
  
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [noCard, setNoCard] = useState(false);
  const [addressOpts, setAddressOpts] = useState<Array<{label: string, value : string}>>([]);
  const [isSubmitting, setIsSubmitting] = useState([]);

  const addressModalProps = {
    modalIsOpen: addressModalIsOpen,
    setModalIsOpen: setAddressModalIsOpen,
    setFieldValue,
    values,
    setIsSubmitting,
    setSelectedAddress,
    wasteCardMode,
    execFetchAddresses
  };

  const wasteAddressProps = {
    setFieldValue,
    values,
    setIsSubmitting,
    setSelectedAddress,
    wasteCardMode,
    addressesState,
  }

 

  // *** side effects *************************************** //

  useEffect(() => {
    const noCard : Array<string>= values[FIELD_NAMES.NO_CARD];
    // setTimeout(() => validateForm());
    if(noCard.length > 0){
      setFieldValue(FIELD_NAMES.CARD_NR, "" );
          setNoCard(true);
    }else{
      setNoCard(false);
    }
    
    // setTimeout(() => validateForm());
  }, [values[FIELD_NAMES.NO_CARD]]);

  useEffect(() => {
    
    // setTimeout(() => validateForm());
    if(values[FIELD_NAMES.DATE]){
      setTimeout(() => setFieldTouched(FIELD_NAMES.DATE, true));
    }
    
    // setTimeout(() => validateForm());
  }, [values[FIELD_NAMES.DATE]]);

  /** refresh props for card header texts */
  useEffect(() => {
    if (wasteCardMode) {
      setCardNr(values[FIELD_NAMES.CARD_NR]);
    }
    
    setCardDate(values[FIELD_NAMES.DATE]);
    let selectedAddress = _.find(addressesState.data, (item) => {
      return  item.id == values.waste_address;
   })
   if(selectedAddress){
    setSelectedAddress(selectedAddress);
   }
  
  }, [values]);

  //** set selected address_id in dropdown (selectedAddress is changed after adding new address) */
  useEffect(() => {
    if(selectedAddress){
      setFieldValue('address_id', selectedAddress.id )
    }
  }, [selectedAddress]);

  /** set selectedAddress OBJECT prop on every address dropdown (id) change */
  useEffect(() => {
    const addressId = values[FIELD_NAMES.WASTE_ADDRESS];
    if (addressId) {
      const selectedAddress = _.find(addressesState.data, (item) => {
         return  item.id == addressId;
      })
      setSelectedAddress(selectedAddress);
    }
  }, [values[FIELD_NAMES.WASTE_ADDRESS]]);

  useEffect(() => {
    validateForm();
  }, []);


  useEffect( () => {
    const addresses = addressesState.data;
    if(addresses){
      if(!addresses.length){
        setAddressOpts([]);
      }else{
        const opts = addresses.map((item: IWasteAddress,index: number)=>{
          return {label : `${item.city} ${item.aup_type  != '-' ? item.aup_type : ""} ${item.street} ${item.building_number}${item.locum_number ? `/${item.locum_number}` : "" } ${item.postal_code} ${item.post_office}`, value : item.id}
        })
        
        setAddressOpts(opts);
      }
    }
  }, [addressesState.data]);
  
  

  // *** form fields *************************************** //
  const formFields = {
    [FIELD_NAMES.CARD_NR]: {
      name: FIELD_NAMES.CARD_NR,
      type: "text",
      label: "Numer karty",
      required: !noCard,
      disabled : noCard
    },

    no_card_number: {
      id: `no_card_number`,
      name: FIELD_NAMES.NO_CARD,
      cbxLabel: "Nie posiadam numeru karty",
      value : 'true'
    },

    manufacture_date: {
      name: FIELD_NAMES.DATE,
      type: "date",
      label: wasteCardMode ? "Data przekazania odpadu" : "Data wytworzenia odpadu",
      required: true,
   
    },
    address_id: {
      name: FIELD_NAMES.WASTE_ADDRESS,
      type: "text",
      label: "Miejsce generowania odpadu",
      required: true,
      options: addressOpts
    },
  };
  
  return (
    <div id="step-1">
      {(isSubmitting || isLoading) && <SpinnerPopup/>}
      <AddressModal {...addressModalProps} />
      <table className="details" cellSpacing="1">
        <tbody>
        {wasteCardMode &&
          <TableField {...formFields[FIELD_NAMES.CARD_NR]}>
            <div>
              <TextField {...formFields[FIELD_NAMES.CARD_NR]} />
            <div className="mt-10">
            <CheckFormField  {...formFields.no_card_number}/>
            </div>
          </div>
          </TableField>
        }
         
          <TableTextField {...formFields.manufacture_date} />
          <TableField {...formFields[FIELD_NAMES.WASTE_ADDRESS]}>
            <div className={style.address_field}>
              <div>
                <TableSelectField {...formFields.address_id} />
              </div>
              <div>
                <a className="primary-link" onClick={() => setAddressModalIsOpen(true)}>dodaj adres</a>
              </div>
            </div>
          </TableField>
        </tbody>
      </table>
      <div className="footnotes">
        {/* <div>
          <b class="required">*</b> - pola oznaczone gwiazdką są obowiązkowe
        </div> */}
      </div>
      {/* <div className="buttons">
        <a className="button button-link" onClick={() => setStep(2)}>
          Następny krok
        </a>
      </div> */}
    </div>
  );
}

export default Step1;
