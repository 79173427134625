import React, {useState, useEffect} from "react";
import {useHistory} from 'react-router-dom';
import { Link } from "react-router-dom";
import PageHeader from "components/common/page-header";
// import { Link } from "react-router-dom";
import style from "./waste.module.scss";
import { PATH } from "../../../path";
import WasteListTabs from "./waste-tabs/waste-tabs";
import ReportModal from './report-modal';
 
function Waste() {
  const [reportVisible, setReportVisible] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    const isHistory = urlParams.get('history');
    
    history.push({
      pathname: '/',
      search: `?${isHistory ? 'history=true' : ''}`,
    });
  }, []);
  
  return (
    <div className="body">
      <ReportModal modalIsOpen={reportVisible} closeModal={()=> setReportVisible(false)} />
      <div id="services" className="services-browse module-panel">
        <PageHeader title="Administracja odpadów">
    
          <Link to={`${PATH.WASTE_CARD_CREATE}?wastecard=false&step=1`} className="button button-link ">
            Dodaj odpad bez karty
          </Link>
          <Link
            to={`${PATH.WASTE_CARD_CREATE}?wastecard=true&step=1`}
            className="button button-link mr-10"
          >
            Dodaj odpad z kartą
          </Link>
          <div className={style['report-btn-container']}>
          <button
            type="button"
            // disabled
            className="button button-link inverted-button  mr-10"
            onClick={() => { setReportVisible(true)}}
          >
            Zamów sprawozdanie BDO
          </button>
          {/* <div className="mt-5">Dostępne wkrótce</div> */}
          </div>
        </PageHeader>
        <div className="mb-30 mt-30">
        <p>
          Magazyn – lista zapisanych odpadów – pokazuje sumę wytworzonych kilogramów, w tym ile jest przekazanych, a ile pozostaje na magazynie (nieprzekazanych). Kliknięcie na nazwę odpadu otwiera okno szczegółowego podglądu odpadu.
          <br/>

          <p className="md-b-t">Dostępne funkcjonalności:</p>

        </p>
        <ul className="info-list">
          <li>
          Jeżeli masz Kartę Przekazania skorzystaj z opcji <span className="md-b-t">Dodaj Odpad z Kartą</span>. Jeżeli nie masz to <span className="md-b-t">Dodaj odpad bez karty</span>
          </li>
          <li>
          <div className="mb-5">Przekazanie odpadu osobie fizycznej: </div>
          <ol>
            <li>
            Zaznacz checkbox obok odpadu, który chcesz przekazać.
            Zaznaczenie jest niedostępne jeżeli odpad jest już w całości przekazany lub jest to odpad niebezpieczny (oznaczony gwiazdką przy kodzie odpadu)
            </li>
            <li>
            Kliknij przycisk na dole listy „<span className="md-b-t">Przekaż odpad osobie fizycznej</span>”.
              Jeżeli masz już dodane wszystkie odpady i wszystkie przekazania za 2020 rok to <span className="md-b-t">Zamów sprawozdanie BDO </span>
            </li>
          </ol>
          </li>
          <li>
          <div className="mb-5">Przekazanie odpadu firmie: </div>
          <ol>
            <li>
            Zaznacz checkbox obok odpadu, który chcesz przekazać.
            Zaznaczenie jest niedostępne jeżeli odpad jest już w całości przekazany.
            </li>
            <li>
            Kliknij przycisk na dole listy „<span className="md-b-t">Przekaż odpad firmie</span>”.
              
            </li>
          </ol>
          </li>

        </ul>



          
          </div>
          
        <WasteListTabs></WasteListTabs>
        {/* <WasteSearchForm /> */}
      </div>
    </div>
  );
}

export default Waste;
