import React from "react";
import { Field, ErrorMessage } from "formik";
import inputStyle from "./table-fields.module.scss";

interface IProps {
  disabled? : boolean
  required? : boolean
  label : string
  name : string
  type : string
}

const ErrorMsg = (msg: string) => <span className={inputStyle.validation_error}>{msg} </span>;

function TableTextField(props: IProps) {
  const { disabled, label,  name, required } = props;

  return <><tr  className="p5-row">
      <td data-testid="field-label" className={`caption ${required && "mandatory"}`}>
        {label}
      </td>
      <td className="value">
        <Field data-testid="table-text-field" className="text" {...props} disabled={disabled}></Field>
        <ErrorMessage name={name} render={ErrorMsg} />
      </td>
    </tr>
    </>
  
}

export default TableTextField;
