import React, { Component } from "react";
// import { Link } from "react-router-dom";
import style from "./navbar.module.scss";

import { IUser } from "types/user-types";
const enhanceWithClickOutside = require("react-click-outside");

type MenuProps = {
  user: IUser;
  setUserMenuVisible: (a: boolean) => void;
  execOpenMenuLink: (a: string) => void;
};

interface IResUrl {
  url: string;
}

class UserMenu extends Component<MenuProps> {
  constructor(props: any) {
    super(props);
  }

  handleClickOutside() {
    this.props.setUserMenuVisible(false);
  }

  async openMenuLink(url: string) {
    const { execOpenMenuLink } = this.props;
    let urlObj = new URL(url);
    const res: any = await execOpenMenuLink(urlObj.search);
    const data: IResUrl = res.data;
    window.location.href = data.url;
  }

  async handleLogout(url: string) {
    const { execOpenMenuLink } = this.props;
    let urlObj = new URL(url);
    const res: any = await execOpenMenuLink(urlObj.search);
    localStorage.removeItem('token');
    const data: IResUrl = res.data;
    window.location.href = data.url;
  }

  renderItems() {
    const { user } = this.props;
    const items = user.dropdown_items;
    return Object.keys(user.dropdown_items).map((key, index) => {
      return (
        <span>
          {/* {( key === 'change_password') && 
          <img
          src="data/Structure/menu-editaccount_1.png"
          alt=""
          className="icon"
        />} */}
          {items[key].label == "Wyloguj" ? (
            <a
              onClick={() => this.handleLogout(items[key].url)}
              className="item-level-2  first"
            >
              <img src="data/Structure/login.png" alt="" className="icon" />{" "}
              {items[key].label}
            </a>
          ) : (
            <a
              onClick={() => this.openMenuLink(items[key].url)}
              className="item-level-2  first"
            >
              <img src="data/Structure/login.png" alt="" className="icon" />{" "}
              {items[key].label}
            </a>
          )}
          
        </span>
      );
    });
  }

  render() {
    const { user } = this.props;
    return (
      <div id="Submenu-44" className={style.user_submenu}>
        <div className={style.arrow_top}></div>

        <div className="user-box">
          Jesteś zalogowany jako:
          <div className="name">
            {user.name} {user.surname}
          </div>
          <div className="email">{user.email}</div>
          <div className="success-box full">Pełna wersja serwisu</div>
        </div>
        <div className="company-box">
          <div className="name">{user.company_name}</div>
          {/* <div className="address">pl. Kilińskiego Jana Pl. 2, 35-005 Rzeszów</div>
          <div className="taxnumber">NIP: 5170357672</div> */}
        </div>

        {this.renderItems()}
      </div>
    );
  }
}

export default enhanceWithClickOutside(UserMenu);
