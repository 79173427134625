import React from 'react'
import {Link} from 'react-router-dom';
import styles from './not-found.module.scss';
const Card = () => {

  return (
    <div className={`${styles['not-found']}`} >
      <div className={`${styles.error}`}>404 </div>
      <div className={styles.info}>Podana strona nie istnieje</div>
      <div className={styles.buttons}>
        <Link className="button primary" to="/">Do strony głównej</Link>
      </div>
      
    </div>
  )
}

export default Card;
