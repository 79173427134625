import * as Yup from "yup";
import {
  required,
  minLength,
  phoneNumber,
  nipNumber,
  regonNumber,
} from "../validators/index";
import { addressValidation } from "validators/address-schema";
import { IRegisterFormValues } from "types/waste-types";
/* field name consts */
export const FIELD = {
  SERVICE_OPTION: "service_option",
  NIP: "client.tax_number",
  FORCE_NIP : "client.force_tax_number",
  BDO_NR: "client.bdo_number",
  NO_BDO: "client.no_bdo",
  LEGAL_FORM: "client.legal_form",
  CLIENT_TYPES: "client.client_types",
  COMPANY_NAME: "client.company_name",
  REGON: "client.regon_number",
  FORCE_REGON : 'client.force_regon_number',
  PHONE: "client.phone",
  EMAIL: "client.email",
  TERMS: "client.terms",
  ZIP_CODE: "address.postal_code",
  AUP_TYPE: "address.aup_type",
  CITY: "address.city",
  STREET: "address.street",
  POST_OFFICE: 'address.post_office',
  BUILDING_NR: "address.building_number",
  LOCUM_NR: "address.locum_number",
  VOIVODESHIP: "address.voivodeship",
};

interface selectOption {
  label: string;
  value: number;
}

interface checkOption {
  label: string;
  id: string;
  value: number;
}

/* field config */
export const BDOField = (props: any) => ({
  name: FIELD.BDO_NR,
  type: "text",
  label: "Numer BDO",
  required: !props.noBDO,
  disabled: props.noBDO,
  // validate : null
});

export const nipField = (props: any) => ({
  name: FIELD.NIP,
  type: "text",
  label: "NIP przedsiębiorcy",
  required: true,
  onChange: props.preloadCompany,
  maxLength: "10",
});

export const basicFields = (props: any) => ({
  [FIELD.LEGAL_FORM]: {
    name: FIELD.LEGAL_FORM,
    type: "select",
    label: "Forma prawna",
    required: true,
    options: props.parseLegalForms(),
  },
  [FIELD.CLIENT_TYPES]: {
    name: FIELD.CLIENT_TYPES,
    type: "checkbox",
    label: "Typ klienta",
    required: true,
    options: [
      { value: "service", id: "vservice", cbxLabel: "warsztat" },
      { value: "importer", id: "importer", cbxLabel: "importer" },
      { value: "dealer", id: "dealer", cbxLabel: "komis" },
    ],
  },
  [FIELD.COMPANY_NAME]: {
    name: FIELD.COMPANY_NAME,
    type: "text",
    label: "Nazwa firmy",
    required: true,
  },
  [FIELD.REGON]: {
    name: FIELD.REGON,
    type: "text",
    label: "Regon",
    required: true,
    maxLength: "9",
    onChange: props.onRegonChange,
  },
  [FIELD.FORCE_REGON]: {
    name: FIELD.FORCE_REGON,
    type: "checkbox",
    // label: "Wymuszenie zgodności REGON-u",
    onCheckChange: props.onForceRegonChange,
    options: [
      {
        value: "true",
        id: "forceRegon",
        cbxLabel: "Wymuszenie zgodności REGON-u",
      },
      
    ],
  },
  [FIELD.FORCE_NIP]: {
    name: FIELD.FORCE_NIP,
    type: "checkbox",
    // label: "Wymuszenie zgodności NIP-u",
    onCheckChange: props.onForceNipChange,
    options: [
      {
        value: "true",
        id: "forceNip",
        cbxLabel: "Wymuszenie zgodności NIP-u",
      }
      
    ],
  },
  [FIELD.PHONE]: {
    name: FIELD.PHONE,
    type: "tel",
    label: "Telefon",
    required: true,
  },
  [FIELD.EMAIL]: {
    name: FIELD.EMAIL,
    type: "text",
    label: "Email",
    required: true,
  },
  [FIELD.TERMS]: {
    name: FIELD.TERMS,
    type: "checkbox",
    label: "Akceptuję regulamin",
    required: true,
    options: [{ value: "basic", id: "basic", label: "Akceptuję" }],
  },
});

export const address1Fields = (props: any) => [
 
  {
    name: FIELD.VOIVODESHIP,
    type: "select",
    label: "Województwo",
    required: true,
    disabled : true,
    options: [
      { value: "dolnośląskie", label: "dolnośląskie" },
      { value: "kujawsko-pomorskie", label: "kujawsko-pomorskie" },
      { value: "lubelskie", label: "lubelskie" },
      { value: "lubuskie", label: "lubuskie" },
      { value: "łódzkie", label: "łódzkie" },
      { value: "małopolskie", label: "małopolskie" },
      { value: "mazowieckie", label: "mazowieckie" },
      { value: "opolskie", label: "opolskie" },
      { value: "podkarpackie", label: "podkarpackie" },
      { value: "podlaskie", label: "podlaskie" },
      { value: "pomorskie", label: "pomorskie" },
      { value: "śląskie", label: "śląskie" },
      { value: "świętokrzyskie", label: "świętokrzyskie" },
      { value: "warmińsko-mazurskie", label: "warmińsko-mazurskie" },
      { value: "wielkopolskie", label: "wielkopolskie" },
      { value: "zachodniopomorskie", label: "zachodniopomorskie" },
    ],
  },
  {
    name: FIELD.CITY,
    type: "select-autocomplete",
    label: "Miejscowość",
    required: true,
    options : props.cityOpts,
    value : props.values.address.city ? {value : props.values.address.city , label : props.values.address.city} : null,
    onChange: (option: selectOption) => {
      if (option) {
        props.setFieldValue(FIELD.CITY, option.value);
      }
      else{
        props.setFieldValue(FIELD.CITY, "");
      }
    }
  },
  {
    name: FIELD.AUP_TYPE,
    type: "select",
    label: "Typ adresu",
    options: [
      { value: "-", label: "-" },
      { value: "ul.", label: "Ulica" },
      { value: "pl.", label: "Plac" },
      { value: "al.", label: "Aleja" },
      { value: "os.", label: "Osiedle" },
    ],
    required: true,
  },
  {
    name: FIELD.STREET,
    type: "select-autocomplete",
    label: "Ulica",
    required: !props.streetDisabled,
    isDisabled: props.streetDisabled,
    // value : props.values.street,
    options: props.streetOpts,
    value : props.values.address.street ? {value : props.values.address.street , label : props.values.address.street} : null,
    onChange: (option: selectOption) => {
      if (option) {
        props.setFieldValue(FIELD.STREET, option.value);
      }
      else{
        props.setFieldValue(FIELD.STREET, "");
      }
    }
  },
  {
    name: FIELD.POST_OFFICE,
    type: "select-autocomplete",
    label: "Poczta",
    required : true,
    options: props.postalOfficeOpts,
    value : props.values.address.post_office ? {value : props.values.address.post_office , label : props.values.address.post_office} : null,
    onChange: (option: selectOption) => {
      if (option) {
        props.setFieldValue(FIELD.POST_OFFICE, option.value);
      }
      else{
        props.setFieldValue(FIELD.POST_OFFICE, "");
      }
    }
  },
  {
    name: FIELD.BUILDING_NR,
    type: "text",
    label: "Numer domu",
    required: true,
  },
  { name: FIELD.LOCUM_NR, type: "text", label: "Numer lokalu" },
];

//** validation schema */
export const stepsValidationSchema : {[key : number] : any} = {
  1: Yup.object().shape({
    service_option: Yup.string().concat(required), 
  }),

  2: Yup.object().shape({
    client: Yup.object().shape({
      no_bdo: Yup.string(),
      tax_number: Yup.string().concat(required).concat(nipNumber).nullable(),
      bdo_number: Yup.string().when("no_bdo", {
        is: "true",
        then: Yup.string().notRequired(),
        otherwise: Yup.string().concat(required),
      }),
      legal_form: Yup.string().concat(required),
      email: Yup.string()
        .concat(required)
        .email("Niepoprawny format adresu email"),
      phone: Yup.string().concat(required).concat(phoneNumber).nullable(),
      company_name: Yup.string().concat(required),
      regon_number: Yup.string().concat(required).concat(regonNumber),
    }),
    address: Yup.object().shape(addressValidation),
  }),
};

export const initialValues : IRegisterFormValues = {
  terms: [],
  service_option: ["val1"],
  agreements : [],
  name1 : [],
  waste_collection : [],
  registration_type : "administration",
  client: {
    company_name : "",
    no_bdo: [],
    terms: [],
    client_types: [],
    phone: "",
    email : "",
    tax_number : "",
    bdo_number: "",
    regon_number: "",
    legal_form: "",

    force_tax_number : [],
    force_regon_number : [],
  },
  address:{
      postal_code : "",
      street : "",
      building_number : "",
      city : "",
      id : "",
      aup_type : "",
      post_office : "",
      voivodeship: "",
      

    
  },
};
