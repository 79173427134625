import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import SpinnerPopup from "components/common/spinner-modal";
import { IReportFormValues } from "types/report-types";
import { useAsyncAction } from "utils/async-action";
import TextField from "components/input/text-field";
import TableTextField from "components/input/table-text-field";
import { getReport, submitReport } from "actions/report";
import { Formik, FormikProps } from "formik";
import { IUser } from "types/user-types";
import { IActionState } from "types/async-types";
import * as Yup from "yup";
import { required } from "validators/index";
import _ from "lodash";
import {
  IReportState,
  IGetReport,
  ISubmitReport,
  IReport,
  IProduced,
  ITotal,
  ITransferedWithCard,
  ITransferedWithoutCard,
} from "types/report-types";
import styles from "./report-modal.module.scss";

interface IProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  submitReportState: IActionState;
  displayedEmail: string;
  setDisplayedEmail: any;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "1100px",
    height: "80%",
  },
};

const mailModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "200px",
  },
};

// const mockData: IReport = {
//   produced: [{ code: "12_34", name: "Szkło", kg: 12, mg: 0.012 }, { code: "12_34", name: "Szkło", kg: 12, mg: 0.012 }],
//   produced_total: {kg: 12, mg: 0.012},
//   transfered_with_card: [{ code: "12_34", name: "Szkło", kg: 12, mg: 0.012 }],
//   transfered_with_card_total: {kg: 12, mg: 0.012},
//   transfered_without_card: [{ code: "12_34", name: "Szkło", kg: 12, mg: 0.012 }],
//   transfered_without_card_total: {kg: 12, mg: 0.012},
// };

const ReportModal = (props: IProps & FormikProps<IReportFormValues>) => {
  const user: IUser = useSelector((state: any) => state.user);
  const {
    modalIsOpen,
    closeModal,
    submitForm,
    isValid,
    values,
    setFieldValue,
    submitReportState,
    resetForm,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [mailModalIsOpen, setMailModalIsOpen] = useState(false);
  const [displayedEmail, setDisplayedEmail] = useState<string | null>(
    user.email
  );

  const [reportState, execGetReport]: readonly [
    IReportState,
    IGetReport
  ] = useAsyncAction(getReport);

  //** side effects */
  useEffect(() => {
    if (modalIsOpen) {
      const year = "2020";
      execGetReport(year);
      setFieldValue("email", user.email);
      setDisplayedEmail(user.email);
      resetForm();
    }
  }, [modalIsOpen]);

  useEffect(() => {
    if (reportState.error) {
      closeModal();
    }
  }, [reportState.error]);

  useEffect(() => {
    if (submitReportState.data) {
      closeModal();
    }
  }, [submitReportState.data]);

  const renderProduced = (items: IProduced) => {
    return Object.keys(items).map((key) => {
      return (
        <tr>
          <td className="text-left">
            {key} {items[key].code_name}
          </td>
          <td className="text-center">{_.round(items[key].produced_kg, 4)}</td>
          <td className="text-center">{_.round(items[key].produced_mg, 4)}</td>
        </tr>
      );
    });
  };

  const renderTransferedWithCard = (items: ITransferedWithCard) => {
    return Object.keys(items).map((key) => {
      return (
        <tr>
          <td className="text-left">
            {key} {items[key].code_name}
          </td>
          <td className="text-center">
            {_.round(items[key].transfered_with_card_kg, 4)}
          </td>
          <td className="text-center">
            {_.round(items[key].transfered_with_card_mg, 4)}
          </td>
        </tr>
      );
    });
  };

  const renderTransferedWithoutCard = (items: ITransferedWithoutCard) => {
    return Object.keys(items).map((key) => {
      return (
        <tr>
          <td className="text-left">
            {key} {items[key].code_name}
          </td>
          <td className="text-center">
            {_.round(items[key].transfered_without_card_kg, 4)}
          </td>
          <td className="text-center">
            {_.round(items[key].transfered_without_card_kg, 4)}
          </td>
        </tr>
      );
    });
  };

  const setNewEmail = () => {
    const email = values.email;
    setDisplayedEmail(email);
    setMailModalIsOpen(false);
  };
  const report = reportState.data;

  const renderReport = () => {
    if (!report) {
      return <> </>;
    }

    
    return Object.keys(report).map((key) => {
      return (
        <div>
          <div className={styles["address-header"]}>
            {report[key].address_string}
          </div>
          <table className="list waste-list no-box-shadow" cellSpacing="1">
            <thead>
              <tr>
                <th className="text-left">Odpady wytworzone w 2020:</th>
                <th>kg</th>
                <th>Mg</th>
              </tr>
            </thead>
            <tbody>
              {renderProduced(report[key].wastes_produced)}
              <tr className="row-summary">
                <td className="text-right">suma</td>
                <td className="text-center">
                  {_.round(report[key].wastes_produced_sum_kg, 4)}
                </td>
                <td className="text-center">
                  {_.round(report[key].wastes_produced_sum_mg, 4)}
                </td>
              </tr>
              <tr className="header-row">
                <td colSpan={3}>Odpady przekazane z kartą KPO w 2020 roku</td>
              </tr>
              {renderTransferedWithCard(report[key].wastes_with_card)}
              <tr className="row-summary">
                <td className="text-right">suma</td>
                <td className="text-center">
                  {_.round(report[key].wastes_with_card_sum_kg, 4)}
                </td>
                <td className="text-center">
                  {_.round(report[key].wastes_with_card_sum_mg, 4)}
                </td>
              </tr>
              <tr className="header-row">
                <td colSpan={3}>Odpady przekazane bez karty KPO w 2020 roku</td>
              </tr>
              {renderTransferedWithoutCard(report[key].wastes_without_card)}
              <tr className="row-summary">
                <td className="text-right">suma</td>
                <td className="text-center">
                  {_.round(report[key].wastes_without_card_sum_kg, 4)}
                </td>
                <td className="text-center">
                  {_.round(report[key].wastes_without_card_sum_mg, 4)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        closeModal();
      }}
      style={customStyles}
    >
      {reportState.loading || submitReportState.loading ? (
        <SpinnerPopup />
      ) : (
        <div className="modal">
          {/* <Modal
            isOpen={mailModalIsOpen}
            onRequestClose={() => {
              setFieldValue("email", displayedEmail);
              setMailModalIsOpen(false);
            }}
            style={mailModalStyles}
          >
            <div className={styles["email-modal"]}>
              <div className="mb-10">Email</div>
              <TextField label="Adres email" type="email" name="email" />
              <div className="buttons center">
                <button
                  disabled={!isValid}
                  className="button"
                  onClick={() => {
                    setNewEmail();
                  }}
                >
                  Zapisz
                </button>
              </div>
            </div>
          </Modal> */}
          <h2 className="mb-10">Zamówienie sprawozdania BDO za 2020</h2>
          <div className={styles["report-container"]}>{renderReport()}</div>

          <p className="text-center">
            Sprawozdanie będzie utworzone na podstawie danych przekazanych przez
            Użytkownika. Integrator nie ponosi odpowiedzialności za
            nieprawidłowe dane.
          </p>
          <p className="text-center">
            Po wysłaniu zamówienia, sprawozdanie zostanie przygotowane i wysłane
            na podany poniżej adres.
            {/* na podany poniżej adres: {displayedEmail} / adres mailowy z CBK */}
          </p>
          <div className={`${styles['report-email']} row`}>
            <div className="col-8 auto-center">
              <table className="details">
                <tbody>
                <TableTextField  label="Adres email" type="email" name="email" />
                </tbody>
              
              </table>
              {/* <span onClick={() => setMailModalIsOpen(true)} className="link">
                Zmień adres
              </span> */}
            </div>
          </div>

          <div className="mt-10 buttons center">
            <button
              type="button"
              className="button inverted-button"
              onClick={closeModal}
            >
              Anuluj
            </button>
            <button
              type="button"
              className="button button-link"
              onClick={submitForm}
              disabled={!isValid}
            >
              Wyślij
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

const ReportModalForm = (props: any) => {
  const [submitReportState, execSubmitReport]: readonly [
    IActionState,
    ISubmitReport
  ] = useAsyncAction(submitReport);

  const user: IUser = useSelector((state: any) => state.user);

  const [displayedEmail, setDisplayedEmail] = useState<string | null>(
    user.email
  );

  const onSubmit = (formValues: IReportFormValues) => {
    
    const email = formValues.email;
    execSubmitReport(email);
  };

  const initialValues: IReportFormValues = { year: "2020", email: user.email };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .concat(required)
      .email("Niepoprawny format adresu email")
      .nullable(),
  });
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formik: FormikProps<IReportFormValues>) => {
        
        
        const formikProps = {
          ...formik,
          submitReportState,
          displayedEmail,
          setDisplayedEmail,
        };
        return <ReportModal {...props} {...formikProps} />;
      }}
    </Formik>
  );
};

export default ReportModalForm;
