import {combineReducers} from 'redux';
import UserReducer from 'redux/reducers/user-reducer';
import AsyncReducer from 'redux/reducers/async-reducer';

export const rootReducer : any = combineReducers({
    user : UserReducer,
    async : AsyncReducer
    
});

export type RootState = ReturnType<typeof rootReducer>