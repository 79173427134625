import axios from "axios";

export function isLoading({isLoading, stateProperty, errorCode} : {isLoading : boolean, stateProperty: string | null, errorCode : number | null}) {
  return function (dispatch: any) {
    if (isLoading) {
      dispatch({
        type: "SET_LOADING",
        payload: { isLoading, property: stateProperty },
      });
    } else {
      dispatch({
        type: "SET_LOADING",
        payload: { isLoading: false, property: null, errorCode },
      });
    }
  };
}
