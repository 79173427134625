import React from "react";
// import { Link } from "react-router-dom";
import style from "./spinner-modal.module.scss";

function spinnerPopup(props) {
  const { title, message } = props;
  return (
    <div className={style.w_glass_panel}>
      <div className={style.window_panel}>
        <div className={style.w_header}>
          <div>{message ? message : "Pobieranie danych..."}</div>
        </div>
        <div className={style.w_body}>
          <div className={style.spinner_window_content}>
            <div>
              Proszę czekać...
              <br />
              <br />
              <img src="/images/loader.gif" />
            </div>
          </div>
        </div>
        <div className={style.w_buttons}></div>
      </div>
    </div>
  );
}

export default spinnerPopup;
