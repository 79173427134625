
export function formatDate(fullDate? : string | null){
    if(fullDate === ' - '){
        return fullDate;
    }
    if(!fullDate){
        return "";
    }
    const date = new Date(fullDate);
    const month = (date.getMonth() + 1);
    const day = (date.getDate())
    let dayStr = "";
    let monthStr = "";
    if(month < 10){
        monthStr = '0' + month
    }else{
      monthStr = month.toString();
    }
    if(day < 10){
        dayStr = '0' + day;
    }else{
        dayStr = day.toString();
    }
     const dateStr = date.getFullYear() + "-" + monthStr + "-" + dayStr
     return dateStr;
    
  }
