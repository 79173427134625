export default function (state = {}, action) {
  switch (action.type) {
    case "AUTH_USER":
      return action.payload.user;
      case "LOGIN_BY_TOKEN":
        return action.payload.user;
    default:
      return state;
  }
}
